<template>
  <div>
    <Suspense>
      <div>
        <RouterView />
      </div>
      <template #fallback>
        <div class="flex h-dvh w-screen flex-col items-center justify-center">
          <div class="pb-32">
            <AtomSpinner :size="52" />
          </div>
          <div>
            <Button v-if="showButton" size="medium" @click="reloadPage">{{
              $t("reload_page")
            }}</Button>
          </div>
        </div>
      </template>
    </Suspense>
  </div>
</template>

<script setup lang="ts">
import { useVersionStore } from "@/stores/appVersionManagementStore"

const versionStore = useVersionStore()
const showButton = ref(false)

setTimeout(() => {
  showButton.value = true
}, 3000)

async function reloadPage() {
  await versionStore.reloadApp()
}
</script>
