<template>
  <PageLayout>
    <template #header>
      <TopNav
        :title="$t('quote.settings.configure_quote')"
        :show-additiol-options="false"
        :saving="projectStore.loading"
        @back="$router.back()"
      />
    </template>

    <template #default>
      <QuoteSettings @valid="val => (settingsInInvalidState = val)" class="mt-4" />
    </template>
    <template #footer-mobile>
      <Button
        v-if="!settingsInInvalidState"
        type="primary"
        class="w-full mr-2"
        size="large"
        @click="showQuote"
        >{{ $t("preview") }}
      </Button>
      <p v-else class="flex justify-center my-1 text-label-1 text-color-label-3">
        {{ $t("quote.settings.missing_information") }}
      </p>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import router from "@/router"
import { useGlobalStore } from "@/stores/globalStore"
import { useProjectStore } from "@/stores/projectStore"
import { useQuoteEmailStore } from "@/stores/quoteEmailStore"

const route = useRoute()
const projectStore = useProjectStore()
const globalStore = useGlobalStore()
const quoteEmailStore = useQuoteEmailStore()
const settingsInInvalidState = ref(true)
const quoteId = route.params.quoteId as string

if (projectStore.project?.id !== quoteId) await projectStore.setProject(quoteId)

if (projectStore.project && quoteEmailStore.projectId !== projectStore.project.id) {
  quoteEmailStore.initState(globalStore.tenant, projectStore.project)
}

async function showQuote() {
  await router.push({
    name: "quote.preview",
    params: {
      quoteId: projectStore.project?.id,
    },
  })
}
</script>

<style lang="scss" scoped></style>
