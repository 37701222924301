<template>
  <div v-if="projectStore.project">
    <div class="flex items-center">
      <div class="mr-3 size-12">
        <RoundImage
          :src="picture"
          :update-image-contex="directusContext"
          @image-updated="updateProject"
        >
          <IconAdd />
        </RoundImage>
      </div>
      <h2 class="my-8 w-full overflow-hidden text-heading-3">
        <TextFieldVal
          v-model="projectStore.project.name"
          text-size="normal"
          name="quote-title"
          size="xl"
          :placeholder="placeholderState.currentText"
          :remove-formatting="true"
          :focus="projectStore.status !== 'edit'"
          @blur="updateProject"
        />
      </h2>
    </div>
    <div v-if="!projectStore.projectIsInAValidState" class="pt-32">
      <p class="mb-4 text-label-1">1. {{ $t("quote.new_quote_notice_1") }}</p>
      <p class="text-label-1">2. {{ $t("quote.new_quote_notice_2") }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProjectStore } from "@/stores/projectStore"
import { DirectusContext } from "@/types"

const projectStore = useProjectStore()
const globalStore = useGlobalStore()
const { t } = useI18n()

async function updateProject(projectImageId?: string) {
  if (projectImageId && projectStore.project) {
    projectStore.project.picture = projectImageId
  }
  if (projectStore.projectIsInAValidState) {
    await projectStore.updateProject()
  }
}

const picture = computed(() => {
  if (!projectStore.project?.picture) return undefined

  return `${api.client.url}/assets/${projectStore.project.picture}?access_token=${globalStore.token}&key=thumbnail`
})
const directusContext: DirectusContext = {
  collection: "projects",
  id: projectStore.project.id,
  property: "picture",
}

const placeholderState = reactive({
  currentText: "",
  fullText: t("quote.quote_name"),
  isTyping: true,
})
let typingInterval: NodeJS.Timeout

onMounted(() => {
  if (placeholderState.isTyping) {
    let index = 0
    typingInterval = setInterval(() => {
      placeholderState.currentText += placeholderState.fullText.charAt(index)
      index++
      if (index > placeholderState.fullText.length) {
        clearInterval(typingInterval)
        placeholderState.isTyping = false
      }
    }, 50) // adjust this duration for faster/slower typing speed
  }
})
</script>
