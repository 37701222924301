<template>
  <div v-if="sortedProject" id="pdf-quote-document">
    <div v-if="documentReady" id="loaded"></div>
    <QuotePDFFrontPage
      v-bind="{ project: sortedProject, colors, tenant, user, jobTitle }"
      @ready="loaded('frontPage')"
    />
    <QuotePDFProjectOverviewPage v-bind="{ project: sortedProject, colors, tenant }" />
    <QuotePDFPhasePages
      v-for="(phase, ind) of sortedProject.phases"
      :key="ind"
      v-bind="{ project: sortedProject, colors, tenant, phaseIndex: ind }"
      @ready="loaded(phase.id)"
    />
    <QuotePDFSummaryPage
      v-if="sortedProject.phases.length > 1"
      v-bind="{ project: sortedProject, colors, tenant }"
    />
    <QuotePDFTermsPage
      v-if="project?.terms && project.terms !== ''"
      v-bind="{ project: sortedProject, colors, tenant }"
    />
    <!-- <QuotePDFAgreementPage v-bind="{ project: sortedProject, colors, tenant }" /> -->
  </div>
</template>

<script setup lang="ts">
import { Color, PdfPhase, PdfProject, PdfTenant, PdfUser } from "@/pdf-generator/types"
import { defineEmits, defineProps, ref, watch } from "vue"

//import QuotePDFAgreementPage from "./QuotePDFAgreementPage.vue"
import QuotePDFFrontPage from "./QuotePDFFrontPage.vue"
import QuotePDFPhasePages from "./QuotePDFPhasePages.vue"
import QuotePDFProjectOverviewPage from "./QuotePDFProjectOverviewPage.vue"
import QuotePDFSummaryPage from "./QuotePDFSummaryPage.vue"
import QuotePDFTermsPage from "./QuotePDFTermsPage.vue"
import { Post } from "@/types"

const props = defineProps<{
  colors: Color
  tenant: PdfTenant
  project: PdfProject
  user: PdfUser
  jobTitle?: string
}>()

const documentReady = ref(false)

const emit = defineEmits(["ready"])

let loadingPages: Record<string, boolean> = {
  frontPage: true,
}

const sortedProject = ref<PdfProject>()
watch(
  () => props.project,
  project => {
    if (!project) return

    const projectCopy: PdfProject = JSON.parse(JSON.stringify(project))
    projectCopy.phases?.sort(
      (phase1: PdfPhase, phase2: PdfPhase) =>
        new Date(phase1?.date_created ?? 0).getTime() -
        new Date(phase2?.date_created ?? 0).getTime(),
    )
    projectCopy.phases?.forEach((phase: PdfPhase) => sortPosts(phase.posts))

    loadingPages = {
      frontPage: true,
    }

    for (const phase of projectCopy.phases) {
      loadingPages[phase.id] = true
    }

    sortedProject.value = projectCopy
  },
  { immediate: true, deep: true },
)

function sortPosts(posts: Pick<Post, "sort" | "date_created">[]) {
  if (posts.length > 1 && posts[0].sort === null) {
    posts.sort((a, b) => {
      const dateA = a.date_created ? new Date(a.date_created) : new Date(0)
      const dateB = b.date_created ? new Date(b.date_created) : new Date(0)
      return dateA.getTime() - dateB.getTime()
    })
  } else {
    posts.sort((a, b) => a.sort - b.sort)
  }
}

function loaded(page: string) {
  loadingPages[page] = false
  checkReadiness()
}

function checkReadiness() {
  if (Object.values(loadingPages).some(Boolean)) return
  documentReady.value = true
  emit("ready")
}
</script>
