<template>
  <div
    class="active:bg-1 my-2 grid grid-cols-3 rounded-2xl bg-1-static px-3 py-4 hover:bg-1-hover active:bg-1-hover"
    @click="updateOrAddToProject"
  >
    <div :class="{ 'col-span-3': mode === 'edit', 'col-span-2': mode === 'select' }">
      <p class="text-label-bold-1 text-color-label-1">{{ customer.name }}</p>
      <p class="truncate text-label-2 text-color-label-2">{{ address }}</p>

      <p class="text-label-3 text-color-label-3">
        {{ orgNo }}
      </p>
    </div>
    <div class="flex items-center justify-center">
      <Button v-if="mode === 'select'" size="small" type="secondary">{{
        $t("select")
      }}</Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from "@/stores/globalStore"
import { Customer } from "@/types"

const { t } = useI18n()

type Props = {
  customer: Customer
  mode: "edit" | "select"
}
const props = defineProps<Props>()
const globalStore = useGlobalStore()

const emit = defineEmits(["edit", "select"])

function updateOrAddToProject() {
  if (props.mode === "edit") {
    emit("edit")
  } else {
    emit("select")
  }
}

const address = computed(() => {
  const components = [
    props.customer.street_address,
    props.customer.zip_code,
    props.customer.city,
  ]
  const filteredComponents = components.filter(Boolean) // removes falsy values
  return filteredComponents.join(", ")
})

const orgNo = computed(() => {
  if (!props.customer.company_registration_number) return ""

  return `${t("org_number_abbrev")}: ${props.customer.company_registration_number}`
})
</script>
