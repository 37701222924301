import type { CapacitorConfig } from "@capacitor/cli"

const config: CapacitorConfig = {
  appId: "app.tredco.ios",
  appName: "tredco",
  webDir: "dist",
  server: {
    androidScheme: "https",
    allowNavigation: ["*"],
  },
  plugins: {
    extConfig: {},
    CapacitorUpdater: {
      autoUpdate: true,
      version: "2.1.32",
      privateKey:
        "-----BEGIN RSA PRIVATE KEY-----\nMIIEpAIBAAKCAQEAuLTVU1/8/ftC0RUqy1stIypC0vQrmDedNpy+f5exefa8vGa+\n0ySwQD+0qPw0cAEoYqjTm1gc2Qmx7t8aXR7BIumKostVuMJglwggIlUXMVXrgkfO\n0TCQb1zKD8cUxOTtDQCI9AZFJR/5QXmOokHoMBdDLBlT4Y2n0uiCaEQaaChDhL+l\n4NneF/MiGzH3avHrZZ86LjLDp2+RSQJS7qLmvnY/z743cdyojYgCn0oCggXSeo/G\nnBQ2gTOjdYOrWTywHXILQDpUcowtBWMv4K04E2vToZ+N27MHg7oUs3+upG9fNQYu\nofwbYoi53A8LphBWGgPIgNaTdbTw7mgP5HKFfQIDAQABAoIBABzua6EM8/pvFN7d\nyz+MdIbW48ivPkowITagrqYbA02bUaIhEIV6Gwa1SShUTie3xrT4GiJxrB3C4FVj\nf3mpNjoFuBVvzBQ4k3CUxpPEyEaOCBRMCHUef5aHQ48ycRULgVCmuMz1qSGmNHYG\nWEp0uwMYDF7Snh+Xqfs7OCdPeONqG+N0K4EClsYSSUzwxEeXufH41Tg8YRU5Y60t\nVQzh4WqNPp/pNiL6iQjQObAvsBESq3HIG+419QXAF4FwJrtorCaEOaz3Y7X5jVv3\nzPZ+ye0RBw6G2dnvuDEapKRqXNm/tbSoZnoxcSgeoJdOvk1eRLiAVVUxkt9okCy8\n25J4aXECgYEA87T09aheur0krXz7/+GHm02g4Jj3To4MpqftbapQKf/uk069WdCz\n4ZXu+Czuhh89doJ0a/udJjNsWjDRlakxNLC3+rwszJnEsWScAUMm11EfxpsVdZNq\nTpjvhxVcNL6Zf6r6+mp5nhQM/1+HxtwKI8dZgIQ+XqQ69FSodOM7h0cCgYEAwgX9\nUo5iAXSVNn20yhLXgRjBa76fiyNM0Lqo6DRKImpOHsJQBMtnlpHidpooZ+rgpyN6\nNboN+eZfgvQ8kjlR879QJ7IDNLflSuCXWAWsaBxu/qOR5kDL8coI437XRDHM167M\n2LYyxQYAWWlb74fXIKF6J4dg6HuJW+6lXNeNNxsCgYEA2J7XQehX02q+SEsY/5fq\n3nuA7UJqSHPxAM2xOFIzkTGBUYSiwEvHKFQKJqRhm+v2yPkqHLV5OPRqcjyEfp/2\nMdyGnCNZOr/PWtKP7HtQ8gCU2wcMawcObjB4cDznSabFb/p9vcqYsY8nAnNaj8MU\nbDsM7jQd8CfQk08QsV0tk48CgYB+uZTEuDSxCKlwxtgvZ/ET48xpiD8CjNLePhyW\nVZjaM57EnlvPVd9Zy/0jQ/sCLLoA0L5Amz4Hq/1UNhvd0cyCLMG4u582bqo3psuS\n9VmvQmsIrGNeBZJvYWs+05jcdL57XTN0lERCxXGy9IrUv1+2c2GqsdSM5CtKPWwK\nYMteYwKBgQCIRjOShQWVp5etFCdTimGyjfBngyS+3rek58iyvpKpJbY73Ay48mWn\nBx7R8pP+3/0A7KxmTN8coSkD3I1XXi6V2DLY/ZlCEbtr77RXsSpDjz4oix8QOWpO\nlETJgQ5lNgc53MgB+Kri24TuVxUyqObSzbPWMZ9jSwBi/8ygOEoAUg==\n-----END RSA PRIVATE KEY-----\n",
    },
  },
}

export default config
