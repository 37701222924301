<template>
  <div v-if="projectStore.project" class="bg-1-static pt-3">
    <span
      class="flex flex-row-reverse text-label-3 text-link-static hover:text-link-hover"
      @click="showConfirmDialog = true"
      >{{ $t("quote.settings.reset_changes") }}</span
    >
    <div class="py-2">
      <TextArea
        v-model="projectStore.project.note"
        @blur="projectStore.updateProject()"
      />
    </div>
    <MobileDialog
      type="confirm"
      :show="showConfirmDialog"
      :sub-title="$t('quote.settings.reset_changes_confirm')"
      @y="resetChanges"
      @n="showConfirmDialog = false"
    />
  </div>

  <AtomSpinner v-else />
</template>

<script setup lang="ts">
import { useProjectStore } from "@/stores/projectStore"
import { DirectusContext } from "@/types"

const emit = defineEmits(["back"])

const projectStore = useProjectStore()
const globalStore = useGlobalStore()
const showConfirmDialog = ref(false)

const directusContext: DirectusContext = {
  collection: "projects",
  id: projectStore.project.id,
  property: "note",
}

async function resetChanges() {
  projectStore.project.note = globalStore.tenant.note
  await projectStore.updateProject()
  showConfirmDialog.value = false
}
</script>
