<template>
  <div>
    <IconOptions class="w-10 h-10 p-2 mr-3" @click="() => (showOptions = true)" />
    <BottomDrawerSlot :show-options="showOptions" @close="() => (showOptions = false)">
      <Button
        type="secondary"
        size="large"
        class="w-full mb-10"
        @click="identifyImageIdToDelete"
        ><p class="text-color-error">{{ t("shared.actions.delete") }}</p></Button
      >
    </BottomDrawerSlot>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { useImageStore } from "@/stores/imageStore"
import { usePostStore } from "@/stores/postStore"
import { useDeleteStore } from "@/stores/useDeleteStore"

const imageStore = useImageStore()
const postStore = usePostStore()
const deleteStore = useDeleteStore()
const showOptions = ref<boolean>(false)

function identifyImageIdToDelete() {
  const id =
    postStore.post.pictures.find(p => p.directus_files_id.id === imageStore.imageId)
      ?.id ?? ""

  deleteStore.setItemToDelete(id, "posts_files")
  postStore.post.pictures = postStore.post.pictures.filter(p => p.id !== id)
}

watch(
  () => deleteStore.itemDeleted,
  async newValue => {
    if (newValue) {
      imageStore.$reset()
      await imageStore.gotoPost(postStore.post.id)
      deleteStore.itemDeleted = false
    }
  },
)
</script>
