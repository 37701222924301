<template>
  <PageLayout v-if="projectStore.project !== null">
    <template #header>
      <div class="flex items-center justify-between">
        <IconButton @click="$router.push({ name: 'quotes' })" />
        <Button type="secondary" @click="goToCustomers">
          <p class="truncate max-w-40">{{ projectStore.quoteLabelName }}</p>
        </Button>
        <QuoteOptions />
      </div>
    </template>
    <hr />
    <div>
      <div>
        <div>
          <div class="flex items-center justify-between">
            <div class="w-1/5"></div>
          </div>
          <QuoteHeader />
          <div v-if="projectStore.projectIsInAValidState">
            <div class="border-2 rounded-2xl">
              <Button
                icon="plus"
                type="secondary"
                class="w-full"
                size="large"
                rounded="rounded-t-xl"
                :hide-border="true"
                :loading="projectStore.creatingPost"
                @click="newPost()"
                >{{ $t("quote.new_post") }}</Button
              >
              <hr class="mx-4" />

              <Button
                icon="plus"
                type="secondary"
                class="w-full"
                size="large"
                rounded="rounded-b-xl"
                :hide-border="true"
                @click="newPostFromTemplate"
                >{{ $t("quote.new_post_from_template") }}</Button
              >
            </div>

            <ol class="mb-32">
              <li v-for="post in postStore.posts" :key="post.id">
                <PostSummary
                  :id="post.id"
                  :key="post.id"
                  :name="post.name"
                  :qty="post.qty"
                  :unit="post.unit"
                  :unit-price="post.unit_price"
                  :post-id="post.id"
                  :currency="projectStore.project.customer?.currency"
                />
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <template #footer-mobile>
      <QuoteBottomAction
        :loading="projectStore.downloadingPdf"
        :grand-total="projectStore.grandTotal"
      />
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
const route = useRoute()
const router = useRouter()
const projectStore = useProjectStore()
const postStore = usePostStore()
const showOptions = ref(false)
const showQuoteOptions = ref(false)
const quoteId = route.params.quoteId as string
const activePostId = ref<string | undefined>(undefined)

provide<Ref<boolean>>("showOptions", showOptions)
provide<Ref<string | undefined>>("activePostId", activePostId)

await projectStore.setProject(quoteId)

watch(showOptions, async newShowOptions => {
  if (!newShowOptions) {
    showQuoteOptions.value = false
  }
})

watch(activePostId, async newPostId => {
  if (newPostId) {
    postStore.status = "edit"
    await postStore.loadPost(newPostId)
  }
})

async function newPost() {
  const post = await postStore.newPost()
  postStore.status = "new"
  await router.push({
    name: "post",
    params: { quoteId: projectStore.project.id, postId: post.id },
  })
}

async function goToCustomers() {
  projectStore.status = "edit-pick-customer"
  await router.push({ name: "customers" })
}

async function newPostFromTemplate() {
  projectStore.status = "edit-pick-from-template"
  await router.push({ name: "templates" })
}
</script>
