<template>
  <div ref="container" class="flex justify-between pb-[4.2mm]">
    <div class="text-heading-3">
      {{ isOnlyPhase ? $t("overview") : `${phaseIndex + 1}# ${phase.name}` }}
    </div>
    <div v-if="!isOnlyPhase" class="text-right text-color-label-3">
      <div class="text-heading-3">
        {{ formatCurrency(phaseTotal) }}
      </div>
      <div class="text-caption-2">{{ $t("excl_vat") }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { formatCurrency } from "@/utils/companyUtils"
import { computed, defineEmits, defineProps, ref, watchEffect } from "vue"
import { PdfProject } from "../types"

const props = defineProps<{
  project: PdfProject
  phaseIndex: number
}>()

const phase = computed(() => {
  return props.project.phases[props.phaseIndex]
})

const phaseTotal = computed(() => {
  let total = 0

  for (const post of phase.value.posts ?? []) {
    total += (Number(post.unit_price) ?? 0) * (Number(post.qty) ?? 0)
  }

  return total
})

const isOnlyPhase = computed(() => {
  return props.project.phases.length === 1
})

const container = ref<HTMLElement | null>(null)
const emit = defineEmits<{ ready: [val: number] }>()
watchEffect(() => {
  if (!container.value) return
  emit("ready", container.value.clientHeight)
})
</script>
