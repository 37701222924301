import { config } from "@/config"
import { DirectusContext, Employee, N8nWorklfow, Schema } from "@/types"
import { ISchema } from "@/types/DateTypes"
import { AxiosError } from "axios"
import {
  createDirectus,
  rest,
  authentication,
  readMe,
  updateItem,
  uploadFiles,
  createItem,
} from "@directus/sdk"
import { createToaster } from "@meforma/vue-toaster"

const toaster = createToaster()

class LocalStorage {
  get() {
    const auth_data = JSON.parse(localStorage.getItem("directus-data") ?? "{}")
    const globalStore = useGlobalStore()
    globalStore.token = auth_data.access_token
    return auth_data
  }
  set(data: any) {
    const loggingOut = localStorage.getItem("logging-out") === "true"
    if (!data.refresh_token && !loggingOut) {
      return
    }
    if (loggingOut) {
      localStorage.removeItem("logging-out")
    }
    const globalStore = useGlobalStore()
    globalStore.token = data.access_token
    localStorage.setItem("directus-data", JSON.stringify(data))
  }
}
const storage = new LocalStorage()

export const client = createDirectus<ISchema>(config.apiBaseBath)
  .with(rest())
  .with(
    authentication("json", {
      storage,
      autoRefresh: true,
      msRefreshBeforeExpires: 60 * 1000,
    }),
  )

// Utility function for retrying Directus operations
export async function withRetry<T>(
  fn: () => Promise<T>,
  retries = 3,
  isCreateOp = false,
): Promise<T> {
  try {
    return await fn()
  } catch (error) {
    const axiosError = error as AxiosError
    console.error(axiosError)

    // Handle 409 as a success case
    if (axiosError.response?.status === 409) {
      console.info("[AUTH] 409 Record not unique")
      return {} as T
    }

    console.info("[AUTH] will call client.refresh() from withRetry")
    await client.refresh()
    if (retries > 0) {
      if (
        isCreateOp &&
        (axiosError.response?.status === 401 ||
          axiosError.response?.status === 403 ||
          axiosError.response?.status === 500)
      ) {
        await new Promise(resolve => setTimeout(resolve, 500))
        console.log("retries left: ", retries, "isCreateOp: ", isCreateOp)
        return await withRetry(fn, retries - 1, isCreateOp)
      } else if (!isCreateOp) {
        await new Promise(resolve => setTimeout(resolve, 500))
        return await withRetry(fn, retries - 1, isCreateOp)
      } else if (axiosError.response?.status === 400) {
        throw axiosError
      }
    }
    toaster.error(
      `
      <div class="text-left">
        <p class="mb-3 text-white">Something went wrong.</p>
        <button  class="px-4 mt-1 mb-3 py-1 bg-white hover:bg-red-200 active:bg-red-300 text-red-800 font-semibold rounded-md shadow-sm transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50">Refresh page</button>
      </div>
    `,
      {
        type: "error",
        duration: 10000,
        position: "bottom",
        dangerouslyHTML: true,
        onClick: () => {
          window.location.href = "/"
        },
      },
    )
    throw axiosError
  }
}

async function updateGeneric(directusContext: DirectusContext): Promise<void> {
  return await withRetry(
    async () => {
      const { collection, id, data } = directusContext
      if (!collection || !id || !data) return
      await client.request(
        updateItem(collection, id, data as Partial<Schema[typeof collection]>),
      )
    },
    3,
    true,
  )
}

async function createGeneric(directusContext: DirectusContext): Promise<void> {
  await withRetry(
    async () => {
      const { collection, data } = directusContext
      if (!collection || !data) return

      return client.request(createItem(collection, data as Partial<Employee>))
    },
    3,
    true,
  )
  return
}

async function uploadFile(file: File, tenantId: string): Promise<string | undefined> {
  const acceptedTypes = ["image/jpeg", "image/png", "image/webp"]
  if (!acceptedTypes.includes(file.type)) {
    throw new Error("Invalid file type")
  }

  const bodyFormData = new FormData()
  bodyFormData.append("tenant", tenantId)
  bodyFormData.append("file", file)

  try {
    const uploadResponse = await client.request(uploadFiles(bodyFormData))
    if (!uploadResponse?.id) {
      throw new Error("Failed to upload file")
    }
    return uploadResponse?.id
  } catch (error) {
    const axiosError = error as AxiosError
    console.error(axiosError)
    return undefined
  }
}

async function callN8nWorkflow(n8nWorkflow: N8nWorklfow): Promise<unknown> {
  try {
    const response = await fetch(`${config.n8nWorkflowUrl}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${await api.client.getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...n8nWorkflow,
        token: await api.client.getToken(),
      }),
    })

    if (response.ok) {
      console.log("n8n workflow successfully called")
      return await response.json()
    } else {
      throw new Error("Something went wrong with calling the n8n workflow.")
    }
  } catch (error: any) {
    throw new Error("Something went wrong with calling the n8n workflow.", error)
  }
}

export default {
  callN8nWorkflow,
  uploadFile,
  client,
  readMe,
  updateGeneric,
  createGeneric,
}
