<template>
  <HeaderOnboarding>
    <div class="mb-6 flex flex-col">
      <h2 class="text-heading-3">{{ $t("country.country") }}</h2>
      <p class="mt-2 text-label-2 text-color-label-1">
        {{ $t("country.promt") }}
      </p>
    </div>
    <LimitedOptions
      :options="options"
      @update:options="handleSelction"
      @selected-id="(id: 'NOK' | 'ZAR') => (onboardingStore.currency = id)"
    />
    <Button
      id="submit-code"
      class="mt-4 w-full"
      size="large"
      submit
      @click="() => (onboardingStore.status = 'require-phone')"
      >{{ $t("onboarding.continue_button") }}</Button
    >
  </HeaderOnboarding>
</template>

<script setup lang="ts">
import { useOnboardingStore } from "@/stores/onboardingStore"
import { getLanguageBasedOnIP } from "@/utils/getLanguageBasedOnIp"

const onboardingStore = useOnboardingStore()
const countryBasedOnIp = await getLanguageBasedOnIP()
const countriesSet = getCountriesConfig(countryBasedOnIp.countryCode).limitedOptionsSet
onboardingStore.currency = countryBasedOnIp.currencyCode

const options = ref(countriesSet)

function handleSelction(updateOptions: typeof options.value) {
  options.value = updateOptions
}
</script>
