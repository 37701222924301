<template>
  <div class="fixed left-0 top-1/2 flex w-full flex-col items-center">
    <p class="mb-3 text-label-bold-1 text-color-label-1">
      {{ $t("templates.no_templates_created") }}
    </p>
    <p class="mx-8 max-w-60 text-center text-label-2 text-color-label-2">
      {{ $t("templates.no_templates_message") }}
    </p>
  </div>
</template>
