<template>
  <div v-on-click-outside="closeUserDropdown" class="relative flex flex-row justify-end">
    <div>
      <div v-if="loading" class="flex items-center justify-center w-12 mx-2">
        <AtomSpinner :size="40" />
      </div>

      <ProfilePicture
        v-else
        :allow-edit="false"
        @click="userDropdownOpen = !userDropdownOpen"
      />

      <div
        class="absolute z-50 flex-col px-4 py-8 border border-gray-300 rounded-lg min-w-64 right-2 top-11 bg-2-static drop-shadow-xl"
        :class="userDropdownOpen ? 'flex' : 'hidden'"
      >
        <div class="flex items-center">
          <div class="mr-4 size-12">
            <ProfilePicture />
          </div>
          <div class="ml-2 mr-2">
            <div class="text-heading-3">{{ name }}</div>

            <div class="text-label-bold-2 text-color-label-2">
              {{ globalStore.user?.email }}
            </div>
          </div>
        </div>
        <div class="mt-8">
          <Button class="w-full mb-2" type="secondary" @click="goToProfile">
            {{ $t(`menu-mobile.settings`) }}
          </Button>
          <Button class="w-full mb-2" @click="globalStore.signOut">
            {{ $t("sign_out") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Toaster } from "@/types"
import { vOnClickOutside } from "@vueuse/components"
import { useForm } from "vee-validate"
import * as Yup from "yup"

const router = useRouter()
const { t } = useI18n()

const globalStore = useGlobalStore()
const schema = Yup.object().shape({
  newPassword: Yup.string().required(t("validation.required")),
})

const goToProfile = () => {
  userDropdownOpen.value = false
  router.push({ name: "profile" })
}

const toast = inject<Toaster>("toast")
const loading = ref<boolean>(true)

const name = computed<string>(() => {
  return `${globalStore.user?.first_name} ${globalStore.user?.last_name}` ?? ""
})
loading.value = false

const userDropdownOpen = ref(false)

const firstLetter = computed(() => {
  return (globalStore.user?.first_name ?? "").charAt(0)
})

const logo = ref<string>("")
watchEffect(async () => {
  if (!globalStore.user?.avatar) {
    return
  }
  logo.value = `${api.client.url}/assets/${globalStore.user?.avatar}?access_token=${globalStore.token}&key=thumbnail`
})

function closeUserDropdown() {
  if (userDropdownOpen.value) userDropdownOpen.value = false
}
</script>

<style></style>
