<template>
  <div
    class="py-6"
    :class="{ 'bg-desktop': !isPhone, 'bg-static': isPhone }"
    style="height: 100dvh"
  >
    <div class="mb-4 pl-6">
      <IconTredcoFull @click="reset()" />
    </div>

    <div class="h-full w-full desktop:ml-40 desktop:justify-center">
      <div class="h-full rounded-t-3xl bg-1-static desktop:w-96">
        <OnboardingLandingPage v-if="onboardingStore.status === 'not-started'" />
        <OnboardingCountry v-if="onboardingStore.status === 'country'" />
        <OnboardingSendMobileCode v-if="onboardingStore.status === 'require-phone'" />
        <OnboardingProvideMobileCode v-if="onboardingStore.status === 'provide-code'" />
        <template v-if="globalStore.tenant.name === 'ny eier'">
          <OnboardingProfile v-if="onboardingStore.status === 'profile'" />
        </template>
        <template v-else>
          <OnboardingAccountExists v-if="onboardingStore.status === 'profile'" />
        </template>
        <OnboardingCompany v-if="onboardingStore.status === 'company'" />
        <OnboardingLogo v-if="onboardingStore.status === 'logo'" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useOnboardingStore } from "@/stores/onboardingStore"
import { createFullstoryOnboardingSession } from "@/utils/fullstory"

const globalStore = useGlobalStore()
const onboardingStore = useOnboardingStore()
onboardingStore.determineStatus()
function reset() {
  onboardingStore.status = "not-started"
}

const router = useRouter()
const isPhone = inject("isPhone") as Ref<boolean>
watch(
  () => onboardingStore.status,
  async status => {
    if (status === "completed") {
      await router.push({ name: "quotes" })
    }
  },
  { immediate: true },
)

onMounted(() => {
  createFullstoryOnboardingSession()
})
</script>
<style scoped lang="postcss">
.bg-desktop {
  @apply bg-center bg-no-repeat;
  background-image: url("/img/onboarding-bg.jpg");
}
</style>
