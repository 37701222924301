<template>
  <PageLayout :form-page="true">
    <template #header>
      <TopNav
        :title="$t('company-settings.update-password')"
        :show-additiol-options="false"
        type="edit"
        :saving="globalStore.loading"
        @back="$router.replace({ name: 'profile' })"
      />
    </template>

    <form @submit.prevent="submitForm">
      <!-- Form fields -->
      <TextFieldVal
        name="newPassword"
        :placeholder="$t('auth.new_password')"
        :label="$t('auth.new_password')"
        type="password"
        text-size="normal"
        :bold="false"
        class="pb-4"
      />
      <Button
        :loading="globalStore.loading"
        class="w-full update-button desktop:w-auto"
        size="large"
        submit
      >
        {{ $t("company-settings.update-password") }}
      </Button>
    </form>
  </PageLayout>
</template>

<script setup lang="ts">
import { useGlobalStore } from "@/stores/globalStore"
import { useForm } from "vee-validate"
import * as Yup from "yup"

const { t } = useI18n()

const globalStore = useGlobalStore()

const schema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, t("auth.short_password_error"))
    .required(t("auth.password_required")),
})

const { handleSubmit } = useForm<Yup.InferType<typeof schema>>({
  validationSchema: schema,
})
const submitForm = handleSubmit(f => globalStore.resetPassword(f.newPassword))
</script>

<style scoped></style>
