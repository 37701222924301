import api from "@/api"
import router from "@/router"
import { useGlobalStore } from "@/stores/globalStore"
import { usePostStore } from "@/stores/postStore"
import axios from "axios"
import Konva from "konva"
import { defineStore } from "pinia"

interface DrawImageState {
  fileInput: HTMLInputElement | null
  stageRef: Konva.Stage | null
  layerRef: Konva.Layer | null
  mode: string
  isPaint: boolean
  lastLine: Konva.Line | null
  imageObj: HTMLImageElement | null
  imageSrc: string | null
  stageConfig: {
    width: number
    height: number
  }
  uploadingImage: boolean
  postStore: ReturnType<typeof usePostStore>
  imageId: string
  globalStore: ReturnType<typeof useGlobalStore>
  status: "new" | "edit"
}

export const useImageStore = defineStore("image", {
  state: (): DrawImageState => ({
    fileInput: null,
    stageRef: null,
    layerRef: null,
    mode: "brush",
    isPaint: false,
    lastLine: null,
    imageObj: null,
    imageSrc: null,
    stageConfig: {
      width: 359,
      height: 282,
    },
    uploadingImage: false,
    postStore: usePostStore(),
    imageId: "",
    globalStore: useGlobalStore(),
    status: "new",
  }),
  actions: {
    async loadImageBlob(blob: Blob) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const img = new Image()
        img.onload = () => {
          // Calculate the image's aspect ratio
          const aspectRatio = img.width / img.height

          // Calculate new height based on constant canvas width
          const newHeight = this.stageConfig.width / aspectRatio

          // Save new dimensions in image object
          img.width = this.stageConfig.width // Canvas width is constant
          img.height = newHeight

          // Adjust canvas height to fit the image height
          this.stageConfig.height = newHeight

          this.imageObj = img
          this.imageSrc = reader.result as string
        }
        img.src = reader.result as string
      }
      reader.readAsDataURL(blob)
    },

    async handleImage(event: Event) {
      const target = event.target as HTMLInputElement
      if (!target.files || target.files.length === 0) return

      const file = (event.target as HTMLInputElement).files?.[0]
      if (!file) return
      const acceptedTypes = ["image/jpeg", "image/png", "image/webp"]
      if (!acceptedTypes.includes(file.type)) {
        console.error("File type not accepted")
        target.value = ""
        return
      }
      this.status = "new"
      await this.loadImageBlob(file)
      await this.gotoImage()
    },
    async handleImageFromPath(imagePath: string) {
      try {
        // Convert the image path URL to a Blob
        const response = await fetch(imagePath)
        const blob = await response.blob()

        // Ensure the blob type is one of the accepted image types
        const acceptedTypes = ["image/jpeg", "image/png", "image/webp"]
        if (!acceptedTypes.includes(blob.type)) {
          console.error("File type not accepted")
          return
        }

        this.status = "new"
        await this.loadImageBlob(blob) // Call your existing method to process the Blob
        await this.gotoImage() // Navigate to the image view
      } catch (error) {
        console.error("Error handling image:", error)
      }
    },
    async hydrateStoreWithRemoteImage(url: string) {
      // Fetch image from remote URL
      const response = await axios.get(url, { responseType: "arraybuffer" })

      // Create a blob from the response
      const blob = new Blob([response.data], { type: response.headers["content-type"] })

      // Load the blob into the store
      await this.loadImageBlob(blob)
      await this.gotoImage()
    },
    handleMouseDown(e: Konva.KonvaPointerEvent) {
      console.log("mouse down")
      const node = e.target as Konva.Node
      if (!node) {
        console.error("No node when mouse down is called")
        return
      }

      this.isPaint = true
      if (!e.target) {
        console.error("No target when mouse down is called")
        return
      }
      const pos = e.target.getStage()?.getPointerPosition()
      const layer = this.layerRef?.getStage()

      if (!pos || !layer) {
        console.error("No position or layer when mouse down is called")
        return
      }
      this.lastLine = new Konva.Line({
        stroke: "#df4b26",
        strokeWidth: 5,
        globalCompositeOperation:
          this.mode === "brush" ? "source-over" : "destination-out",
        lineCap: "round",
        lineJoin: "round",
        points: [pos.x, pos.y, pos.x, pos.y],
      })

      layer.add(this.lastLine)
    },
    handleMouseUp() {
      this.isPaint = false
    },
    handleMouseMove(e: Konva.KonvaPointerEvent) {
      if (!this.isPaint || !this.lastLine || !e.target) {
        return
      }
      const pos = e.target.getStage()?.getPointerPosition()

      if (!pos) {
        console.error("No position when mouse move is called")
        return
      }

      const newPoints = this.lastLine.points().concat([pos.x, pos.y])
      this.lastLine.points(newPoints)
    },
    async save() {
      // Start uploading process
      this.uploadingImage = true
      const tenantId = this.globalStore.tenant?.id
      if (!tenantId) {
        console.error("No tenant id found. Cannot save file")
        return
      }
      // Convert the stage (canvas) to a Data URL
      let dataUrl
      if (this.stageRef) {
        dataUrl = this.stageRef.getStage()?.toDataURL({ pixelRatio: 2 })
      }
      if (!dataUrl) {
        console.error("No data url when saving")
        return
      }
      // Convert the Data URL to a Blob
      fetch(dataUrl)
        .then(res => res.blob())
        .then(async blob => {
          // Create a File from the Blob
          const file = new File([blob], "image", { type: blob.type })

          // Save file to the server
          const postId = this.postStore.post.id
          if (!postId) {
            console.error("No post id when saving")
            return
          }
          const responseId = await api.uploadFile(file, tenantId)
          if (!responseId) {
            console.error("No response id when saving")
            return
          }
          await api.quotes.linkFileAndPost({
            posts_id: postId,
            directus_files_id: responseId,
            tenant: tenantId,
          })

          // End uploading process
          this.uploadingImage = false
          await this.postStore.loadPost(postId, true)
        })
    },
    async cancel() {
      const postId = this.postStore.post.id
      if (!postId) {
        console.error("No post id when saving")
        return
      }
      await this.gotoPost(postId)
    },
    async gotoPost(id: string) {
      await router.push({
        name: "post",
        params: { postId: id },
      })
    },
    async gotoImage() {
      await router.push({
        name: "image",
      })
    },
    registerTouchEvents() {
      if (this.layerRef && this.stageRef) {
        const node = this.stageRef.getStage()
        if (!node) throw Error("node is undefined")
        node.on("mousedown touchstart", this.handleMouseDown)
        node.on("mouseup touchend", this.handleMouseUp)
        node.on("mousemove touchmove", this.handleMouseMove)
      } else {
        console.error("No layer or stage or node when mounting")
      }
    },
  },
})
