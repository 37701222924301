<template>
  <SharedPDFPage v-bind="{ colors, tenant, project }">
    <div class="absolute left-[-14mm] top-[-14mm] w-[210mm]">
      <img
        v-if="images.projectPicture"
        :src="getFullImageUrl(images.projectPicture)"
        class="max-h-[100mm] w-full object-cover"
        @load="loaded(images.projectPicture)"
      />
    </div>
    <div class="flex h-full w-full items-center justify-center">
      <div class="flex flex-col items-center justify-between">
        <img
          v-if="images.logo"
          :src="getFullImageUrl(images.logo)"
          class="mb-[9.8mm] mt-[40mm] h-full max-h-[100mm] w-full max-w-[100mm] object-cover"
          @load="loaded(images.logo)"
        />
        <div
          v-else
          id="logo"
          class="mb-[4.2mm] text-heading-3"
          :style="`color: ${colors.primary};`"
        >
          {{ tenant.name }}
        </div>
        <h2
          class="mb-[4.2mm] line-clamp-2 overflow-hidden break-words text-center text-heading-2"
        >
          {{ project.name }}
        </h2>
        <h4 class="text-center text-label-bold-3">
          {{ $t("quote.quote_number") }} {{ project.quote_number }}
        </h4>
      </div>
    </div>
    <div
      class="absolute bottom-0 flex w-full gap-[4.2mm] rounded-[2.8mm] bg-2-static p-[5.6mm]"
    >
      <img
        v-if="images.avatar"
        id="avatar"
        :src="getFullImageUrl(images.avatar)"
        class="h-[25.9mm] w-[25.9mm] shrink-0 rounded-full object-cover"
        @load="loaded(images.avatar)"
      />
      <div class="text-caption-2">
        <div class="text-label-bold-1">{{ user.first_name }} {{ user.last_name }}</div>
        <div v-if="jobTitle" class="pb-[2.8mm]" :style="`color: ${colors.primary};`">
          {{ $t(`company-settings.your-account-settings.jobTitleOptions.${jobTitle}`) }}
        </div>
        <!-- <div class="pb-[2.8mm]">{{ $t("your_contact_at_x", [tenant.name]) }}</div> -->
        <div>{{ user.email }}</div>
        <a
          class="text-color-link-static"
          :href="'tel:' + getLocalPhoneNumber(user.phone_number)"
        >
          {{ getLocalPhoneNumber(user.phone_number) }}
        </a>
      </div>
    </div>
  </SharedPDFPage>
</template>
<script setup lang="ts">
import { primaryKey } from "@/utils/primaryKey"
import { Color, PdfProject, PdfTenant, PdfUser } from "@/pdf-generator/types"
import { getLocalPhoneNumber } from "@/utils/sanitizeMobileNumber"
import { computed, defineEmits, defineProps, inject } from "vue"

import SharedPDFPage from "./SharedPDFPage.vue"

const props = defineProps<{
  project: PdfProject
  colors: Color
  tenant: PdfTenant
  user: PdfUser
  jobTitle?: string // employee.job_title
}>()

const emit = defineEmits(["ready"])

const assetUrl = inject("assetUrl") as (assetId: string) => string
const imagesLoading: Record<string, boolean> = {}

const images = computed(() => {
  const imgs = {
    logo: primaryKey(props.tenant.logo || ""),
    projectPicture: primaryKey(props.project.picture || ""),
    avatar: props.user.avatar || "",
  }

  return imgs
})

function getFullImageUrl(pictureId: string) {
  imagesLoading[pictureId] = true
  return assetUrl(pictureId)
}
function loaded(pictureId: string) {
  imagesLoading[pictureId] = false
  checkItemReady()
}

function checkItemReady() {
  if (Object.values(imagesLoading).some(Boolean)) return
  emit("ready")
}
</script>
