<template>
  <div>
    <div class="space-y-3">
      <template v-for="item in paymentItems" :key="item.id">
        <PaymentPlanItem
          :show-edit-drawer-for-id="showEditDrawerForId"
          :mode="mode"
          :item="item"
          @update:item="updateItem"
          @deleted="deleteItem"
        />
      </template>
    </div>
    <PaymentPlanTotal :percentage="percentageUsed" :mode="mode" />
    <Button
      size="large"
      :type="mode === 'settings' ? 'primary' : 'secondary'"
      icon="plus"
      class="mb-28 mt-4 w-full"
      :class="{ 'desktop:w-auto': mode === 'settings' }"
      @click="addNewItem"
      >{{ $t("shared.actions.add_new_item") }}</Button
    >
  </div>
</template>

<script setup lang="ts">
import { PaymentPlan } from "@/types"

type Props = {
  mode?: "settings" | "quote-settings"
}
withDefaults(defineProps<Props>(), {
  mode: "settings",
})

const paymentItems = defineModel<PaymentPlan[]>({ required: true })

const percentageUsed = computed(() => {
  return paymentItems.value.reduce((acc, item) => acc + Number(item.percentage), 0)
})

const showEditDrawerForId = ref<number | null>(null)
const addNewItem = () => {
  showEditDrawerForId.value = paymentItems.value.length + 1
  paymentItems.value.push({
    id: paymentItems.value.length + 1,
    description: "",
    percentage: "0",
  })
}

const deleteItem = async (id: number) => {
  paymentItems.value = paymentItems.value.filter(item => item.id !== id)
  for (let i = 0; i < paymentItems.value.length; i++) {
    paymentItems.value[i].id = i + 1
  }
}

const updateItem = async (item: PaymentPlan) => {
  const index = paymentItems.value.findIndex(i => i.id === item.id)
  paymentItems.value[index] = item
}
</script>

<style scoped></style>
