<template>
  <div class="rounded-2xl bg-1-static p-4">
    <div class="grid grid-cols-5" @click="collapsed = !collapsed">
      <div class="col-span-4 mr-2 flex flex-col justify-center">
        <p class="object-center text-label-bold-1 text-color-label-1">
          {{ props.title }}
        </p>
        <p v-if="props.subTitle" class="truncate text-label-3 text-color-label-3">
          {{ props.subTitle }}
        </p>
        <p v-if="required && subTitle === ''" class="text-label-3 text-color-error">
          Missing
        </p>
      </div>
      <div class="flex items-center justify-end">
        <IconChevronDown v-if="collapsed" />
        <IconCollapse v-if="!collapsed" />
      </div>
    </div>
    <!-- <hr class="border-1 border-color-divider" /> -->

    <slot v-if="!collapsed"></slot>
  </div>
</template>

<script setup lang="ts">
const collapsed = ref(true)

type Props = {
  title: string
  subTitle?: string | null
  required?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  required: false,
  subTitle: "",
})
</script>
