<template>
  <div>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7071 6.70712L9.29292 5.29291L2.58582 12L9.29292 18.7071L10.7071 17.2929L6.41424 13H20V11H6.41424L10.7071 6.70712Z"
        fill="#0055FF"
      />
    </svg>
  </div>
</template>
