<template>
  <div class="action-bottom-position border bg-1-static shadow-2xl">
    <div class="action-bottom p-3">
      <slot></slot>
    </div>
  </div>
  <div class="pb-3"></div>
</template>

<script setup lang="ts"></script>
