<template>
  <div v-if="type === 'text'">
    <div>
      <div v-show="showSavedMessage" class="flex items-center">
        <IconCheckMark />
        <div class="text-label-2 text-color-label-3">{{ $t("saved") }}</div>
      </div>
      <div v-if="showLaggedLoading" class="ml-1 text-label-2 text-color-link-static">
        {{ $t("saving") }}
      </div>
    </div>
  </div>

  <div v-if="type === 'symbol'" class="relative">
    <AtomSpinner v-if="showLaggedLoading" class="absolute" :size="15" :color="'blue'" />
    <IconCheckMark
      v-if="showSavedMessage"
      class="absolute top-[-2] text-color-link-static"
    />
  </div>
</template>

<script setup lang="ts">
type Props = {
  isLoading?: boolean
  type?: "text" | "symbol"
}
const props = withDefaults(defineProps<Props>(), {
  isLoading: false,
  type: "text",
})

const showSavedMessage = ref(false)
const showLaggedLoading = ref(false)

watch(
  () => props.isLoading,
  isLoading => {
    if (isLoading) {
      showLaggedLoading.value = true
    } else {
      setTimeout(() => {
        showLaggedLoading.value = false
      }, 500)
    }
  },
  { deep: true },
)

watch(showLaggedLoading, () => {
  if (!showLaggedLoading.value) {
    showSavedMessage.value = true
    setTimeout(() => {
      showSavedMessage.value = false
    }, 2000)
  }
})
</script>

<style lang="scss" scoped></style>
