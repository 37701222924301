<template>
  <Button
    :loading="imageStore.uploadingImage"
    type="primary"
    class="w-full mb-3 desktop: desktop:w-auto"
    size="large"
    icon="plus"
    @click="saveOrUpdate"
    >{{ t("shared.actions.save") }}
  </Button>
</template>

<script setup lang="ts">
import { useDeleteStore } from "@/stores/useDeleteStore"

const imageStore = useImageStore()
const postStore = usePostStore()
const projectStore = useProjectStore()
const router = useRouter()
const deleteStore = useDeleteStore()

async function saveOrUpdate() {
  if (imageStore.imageId === "") {
    await moveBackToQuote()
  } else {
    const imageIdToDelete =
      postStore.post.pictures.find(p => p.directus_files_id.id === imageStore.imageId)
        ?.id ?? ""
    deleteStore.setItemToDelete(
      imageIdToDelete,
      "posts_files",
      true,
      t("quote.photos.confirm_replace"),
    )
  }
}

watch(
  () => deleteStore.itemDeleted,
  async newValue => {
    if (newValue) {
      await moveBackToQuote()
      deleteStore.itemDeleted = false
    }
  },
)

async function moveBackToQuote() {
  await imageStore.save()
  imageStore.$reset()
  if (mobile.value) {
    await imageStore.gotoPost(postStore.post.id)
  } else {
    projectStore.status = "edit"
    await router.push({
      name: "quote",
      params: { quoteId: projectStore.project.id },
    })
  }
}
const mobile = inject("isPhone") as Ref<boolean>
</script>
