<template>
  <div>
    <div
      v-if="content.previousPage"
      ref="previousPage"
      class="pb-[7mm] pt-[2.8mm] text-caption-2 font-bold"
    >
      {{ $t("page.continued_from_previous") }}
    </div>
    <div
      v-if="content.posts.length"
      ref="headers"
      class="flex pb-[2.8mm] text-caption-2 opacity-50"
    >
      <div class="w-[15.4mm]">{{ $t("number") }}</div>
      <div>{{ $t("post.type.product") }}</div>
    </div>
    <template v-for="post in content.posts" :key="post.id">
      <QuotePDFItem
        :number="post.index"
        v-bind="{ project, post: post.value }"
        @ready="itemsReady.set(post.value.id, $event)"
      />
    </template>
    <div
      v-if="content.nextPage"
      ref="nextPage"
      class="py-[2.8mm] text-caption-2 font-bold"
    >
      {{ $t("page.continued_on_next") }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { HeightData, ItemPageContent, PdfProject } from "@/pdf-generator/types"
import { defineEmits, defineProps, ref, watch } from "vue"

import QuotePDFItem from "./QuotePDFItem.vue"

const props = defineProps<{
  content: ItemPageContent
  project: PdfProject
}>()

const previousPage = ref<HTMLElement | null>(null)
const headers = ref<HTMLElement | null>(null)
const itemsReady = ref(new Map<string, number>())

const nextPage = ref<HTMLElement | null>(null)
const emit = defineEmits<{
  ready: [id: HeightData]
}>()

watch(
  itemsReady,
  items => {
    if (props.content.previousPage && !previousPage.value) return
    if (!headers.value) return
    if (props.content.nextPage && !nextPage.value) return
    for (const post of props.content.posts) {
      if (!items.get(post.value.id)) return
    }

    const measurement: HeightData = {
      previousPage: previousPage.value?.clientHeight ?? 0,
      headers: headers.value.clientHeight,
      items: items,
      nextPage: nextPage.value?.clientHeight ?? 0,
    }
    emit("ready", measurement)
  },
  { deep: true },
)
</script>

<style>
.items-grid-row {
  @apply grid gap-x-3;
  grid-template-columns: 28px 3fr 1fr 1fr 1fr;
}
</style>
