<template>
  <div>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 13.2728L17.0204 18.2933L18.2932 17.0205L13.2728 12L18.2932 6.97954L17.0204 5.70675L12 10.7272L6.97953 5.70675L5.70674 6.97954L10.7272 12L5.70674 17.0205L6.97953 18.2933L12 13.2728Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script setup lang="ts"></script>
