<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8442 20.4291C12.4473 21.1903 11.5527 21.1903 11.1558 20.4291L5.1641 8.93708C4.72913 8.10282 5.20974 7 6.00828 7L17.9917 7C18.7903 7 19.2709 8.10282 18.8359 8.93708L12.8442 20.4291Z"
      fill="black"
      fill-opacity="0.1"
    />
  </svg>
</template>

<script setup lang="ts"></script>
