<template>
  <PageLayout :form-page="true">
    <template #header>
      <TopNav
        :title="$t('onboarding.profile_1')"
        :show-additiol-options="false"
        type="edit"
        :saving="tenantStore.isUpdatingProfile"
        @back="$router.replace({ name: 'settings' })"
      />
    </template>
    <template #default>
      <EmployeeAcceptInvite />
      <form @submit.prevent="submitForm">
        <TextFieldVal
          v-model="globalStore.user.first_name"
          text-size="normal"
          :label="$t('company-settings.your-account-settings.firstName')"
          name="first_name"
          class="pb-2"
          :bold="false"
          :rules="'required'"
          @blur="submitForm"
        />
        <TextFieldVal
          v-model="globalStore.user.last_name"
          text-size="normal"
          :label="$t('company-settings.your-account-settings.lastName')"
          name="last_name"
          class="pb-2"
          :bold="false"
          @blur="submitForm"
        />
        <TextFieldVal
          v-model="globalStore.user.email"
          text-size="normal"
          rules="email"
          type="email"
          :label="$t('company-settings.your-account-settings.email')"
          name="email"
          :bold="false"
          class="mb-2"
          @blur="submitForm"
        />
        <PhoneInput
          v-model="globalStore.user.phone_number"
          name="phone_number"
          :label="$t('phone')"
          class="mb-3"
          :allow-all-countries="true"
          :directus-context="{
            collection: 'directus_users',
            id: globalStore.user.id,
          }"
          :directus-property="'phone_number'"
          @blur="submitForm"
        />
      </form>

      <div class="p-4 mt-4 rounded-xl bg-2-static shadow-resting">
        <SettingOption
          :item-text="$t('company-settings.update-password')"
          @click="$router.push({ name: 'passwordUpdate' })"
        />
        <SettingOption
          :item-text="$t('company-settings.sign-out')"
          :show-line="false"
          @click="showSignOutPopup = true"
        />
        <SignOutModal
          v-if="showSignOutPopup"
          :cancel-action="cancel"
          :sign-out-action="logout"
        />
      </div>
    </template>
  </PageLayout>
</template>

<script setup lang="ts">
import { useGlobalStore } from "@/stores/globalStore"
import { useTenantStore } from "@/stores/tenantSettingsStore"
import { useForm } from "vee-validate"

const tenantStore = useTenantStore()
const globalStore = useGlobalStore()
const showSignOutPopup = ref(false)

function cancel() {
  showSignOutPopup.value = false
}

async function logout() {
  await globalStore.signOut()
  showSignOutPopup.value = false
}

const { handleSubmit } = useForm()
const submitForm = handleSubmit(async values => {
  await tenantStore.updateProfile(values)
})
</script>
