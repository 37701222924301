<template>
  <div ref="notes" class="pt-[5.6mm]">
    <div class="mb-[5.6mm] rounded-[2.8mm]">
      <div class="text-label-bold-3">{{ $t("notes") }}</div>
      <div class="whitespace-pre-wrap text-caption-2">
        {{ project.note }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watchEffect } from "vue"
import { PdfProject } from "../types"

defineProps<{
  project: PdfProject
}>()

const emit = defineEmits<{
  ready: [value: number]
}>()
const notes = ref<HTMLElement | null>(null)
watchEffect(() => {
  if (!notes.value) return

  emit("ready", notes.value.clientHeight)
})
</script>
