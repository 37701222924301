<template>
  <div class="rounded-2xl pt-4">
    <span
      class="flex flex-row-reverse text-label-3 text-link-static hover:text-link-hover"
      @click="showConfirmDialog = true"
      >{{ $t("quote.settings.reset_changes") }}</span
    >

    <div class="py-2">
      <TextArea
        v-model="projectStore.project.terms"
        @blur="projectStore.updateProject()"
      />
    </div>
    <MobileDialog
      type="confirm"
      :show="showConfirmDialog"
      :sub-title="$t('quote.settings.reset_changes_confirm')"
      @y="resetChanges"
      @n="showConfirmDialog = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from "@/stores/globalStore"
import { useProjectStore } from "@/stores/projectStore"

const projectStore = useProjectStore()
const globalStore = useGlobalStore()

const showConfirmDialog = ref(false)

async function resetChanges() {
  projectStore.project.terms = globalStore.tenant.terms
  await projectStore.updateProject()
  showConfirmDialog.value = false
}
</script>
