<template>
  <form class="w-full" @submit.prevent="submitVerifyForm">
    <TextFieldVal
      name="verificationCode"
      autocomplete="one-time-code"
      :placeholder="t('auth.sms_code')"
      text-size="normal"
      :bold="false"
      class="pb-4"
    >
    </TextFieldVal>
    <Button class="w-full" :loading="globalStore.loading" submit>
      <span>
        {{ t("auth.confirm_sms_code") }}
      </span>
    </Button>
  </form>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { useGlobalStore } from "@/stores/globalStore"
import { useForm } from "vee-validate"
import * as Yup from "yup"

const globalStore = useGlobalStore()

const verifySchema = Yup.object().shape({
  verificationCode: Yup.number().required().typeError("Kun tall er tillatt"),
})
const { handleSubmit: handleVerifySubmit } = useForm<Yup.InferType<typeof verifySchema>>({
  validationSchema: verifySchema,
})
const submitVerifyForm = handleVerifySubmit(async form => {
  await globalStore.verifyCode(form.verificationCode)
})
</script>
