<template>
  <PageLayout v-if="!projectStore.loading">
    <template #header>
      <div class="flex items-center justify-between">
        <h2 class="text-heading-2">
          {{ $t("quotes_mobile.quotes") }}
        </h2>
        <div class="ml-auto"></div>
        <QuoteStatus
          current-status="draft"
          :selected-statuses="projectView.filterStatus"
          :hide-border="true"
          display-options="select-deselect"
          @selected-statuses="projectView.updateFilter"
        />
        <Button v-if="!mobile" size="medium" @click="newProject()">{{
          $t("quotes_mobile.new_quote")
        }}</Button>
      </div>
      <SearchInputV2
        v-if="Object.keys(projectView.dataStore.projectsById).length > 7"
        v-model="projectView.searchTerm"
        class="mt-4"
        @reset="() => (projectView.searchTerm = '')"
      />
    </template>
    <template #default>
      <InfoBox class="mb-3" />
      <div class="grid grid-cols-2 gap-3 mb-32">
        <template v-if="projectView.filteredProjects.length === 0">
          <div v-for="(p, i) in placeholder" :key="i">
            <QuoteSummary />
          </div>
        </template>
        <template v-else>
          <div v-for="q in projectView.filteredProjects" :key="q.id">
            <QuoteSummary
              :quote="q"
              @click="q.id && gotoQuote(q.id)"
              @duplicate="prepareProjectDuplcate"
            />
          </div>
        </template>
      </div>
      <div v-if="mobile" class="fixed inset-x-0 px-4 bottom-20 pb-safe">
        <Button icon="plus" class="w-full" size="large" @click="newProject()">{{
          $t("quotes_mobile.new_quote")
        }}</Button>
      </div>
      <MobileDialog
        :title="$t('quotes_mobile.copy_quote_prompt')"
        :loading="projectStore.loading"
        :show="showDuplicateDialog"
        @y="duplicate"
        @n="() => (showDuplicateDialog = false)"
      />
    </template>
  </PageLayout>
  <Loader v-else />
</template>

<script setup lang="ts">
const projectIdToDuplicate = ref<string>("")
const showDuplicateDialog = ref(false)
const projectStore = useProjectStore()
const router = useRouter()

const projectView = useProjectsView()

const gotoQuote = async (quoteId: string) => {
  if (!showDuplicateDialog.value) {
    await router.push({
      name: "quote",
      params: { quoteId },
    })
    projectStore.status = "edit"
  }
}

async function newProject() {
  await projectStore.startNewProject()
  projectStore.status = "new-pick-customer"
  await router.push({ name: "customers" })
}

async function duplicate() {
  const newProject = await projectStore.duplicate(projectIdToDuplicate.value)
  showDuplicateDialog.value = false
  if (!newProject) return
  await router.push({ name: "quote", params: { quoteId: newProject.id } })
  projectStore.status = "edit"
}

function prepareProjectDuplcate(id: string) {
  projectIdToDuplicate.value = id
  showDuplicateDialog.value = true
}

const placeholder = new Array(10).fill(undefined)
const showBottomNav = inject("showBottomNav") as Ref<boolean>
onUnmounted(() => {
  showBottomNav.value = false
})

onMounted(async () => {
  projectStore.status = "idle"
  showBottomNav.value = true
})
const mobile = inject("isPhone") as Ref<boolean>
</script>
