import { t } from "@/localization/i18n"
import type { Phase, Post, Project, User, Tenant } from "@/types"
import api from "@/api"

interface NewProjectContext {
  user: User | null
  tenant: Tenant | null
  employee: { job_title?: string } | null
}

export async function scaffoldNewProject(
  context: NewProjectContext,
): Promise<Partial<Project>> {
  const { user, tenant, employee } = context

  return {
    name: "",
    contact_person: `${user?.first_name} ${user?.last_name}`,
    contact_person_email: user?.email,
    contact_person_phone: user?.phone_number ?? "",
    contact_person_role: t(
      `company-settings.your-account-settings.jobTitleOptions.${employee?.job_title}`,
    ),
    terms: tenant?.terms,
    note: tenant?.note,
    payment_plan: tenant?.payment_plan,
    quote_number: (await api.quotes.getHighestQuoteNumber()) + 1,
    customer_price: 0,
    phases: [
      {
        name: "Fase 1",
        tenant: tenant?.id ?? null,
      },
    ],
    scope: "",
    tenant: tenant?.id,
    invoice_due_in_days: tenant?.invoice_due_in_days,
    visible_vat: tenant?.visible_vat,
    visible_post_description: tenant?.visible_post_description,
    visible_post_price: tenant?.visible_post_price,
    visible_post_qty: tenant?.visible_post_qty,
    date_issued: new Date().toISOString().split("T")[0],
    date_expire: new Date(
      new Date().setDate(new Date().getDate() + (tenant?.quote_expire_in_days || 0)),
    )
      .toISOString()
      .split("T")[0],
  }
}

export async function duplicateProject(
  originalProject: Project,
  tenantId: string,
): Promise<Partial<Project>> {
  const newProject: Partial<Project> = {
    tenant: tenantId,
    invoice_due_in_days: originalProject.invoice_due_in_days,
    visible_vat: originalProject.visible_vat,
    visible_post_description: originalProject.visible_post_description,
    visible_post_price: originalProject.visible_post_price,
    visible_post_qty: originalProject.visible_post_qty,
    payment_plan: originalProject.payment_plan,
    contact_person: originalProject.contact_person,
    contact_person_email: originalProject.contact_person_email,
    contact_person_phone: originalProject.contact_person_phone,
    terms: originalProject.terms,
    note: originalProject.note,
    quote_number: (await api.quotes.getHighestQuoteNumber()) + 1,
    customer: originalProject.customer?.id,
    name: originalProject.name ? `** ${t("copy")} ** ${originalProject.name}` : undefined,
  }

  if (originalProject.phases) {
    newProject.phases = originalProject.phases.map((phase: Partial<Phase>) => ({
      tenant: tenantId,
      name: phase.name,
      posts: phase.posts?.map((post: Partial<Post>) => ({
        tenant: tenantId,
        name: post.name,
        description: post.description,
        unit_price: post.unit_price,
        qty: post.qty,
      })),
    }))
  }

  return newProject
}
