<template>
  <div>
    <div
      v-if="versionStore.promtToUpdate && versionStore.environment !== 'local-dev'"
      :class="[
        'fixed',
        'bg-white',
        'p-6',
        'shadow-lg',
        phone ? 'bottom-10 left-2 w-1/2' : 'bottom-2 right-3 w-1/6',
      ]"
    >
      <div class="notification-header"></div>
      <div class="notification-body">
        <div class="notification-button">
          <p class="pb-3 text-center text-sm">
            {{ $t("new_version_of_tredco", ["Tredco"]) }} <br />{{ $t("is_available")
            }}<br />
          </p>
          <p class="text-center">
            <span class="text-xs"></span>
          </p>
        </div>
        <div class="refresh-button text-center">
          <Button @click="versionStore.reloadApp">
            {{ $t("load_again") }}
          </Button>
        </div>
      </div>
    </div>

    <div
      v-if="false"
      class="fixed bottom-0 right-0 z-40 pb-safe"
      @click="gotoLinkThatIsBeingWorkedOn"
    >
      <span
        v-if="$route.name && !$route.name.toString().includes('preview.raw')"
        class="mr-3 text-caption-2 opacity-20"
        >{{ versionStore.versionSnippetCurrent }}</span
      >
    </div>
  </div>
</template>
<script setup lang="ts">
import { useVersionStore } from "@/stores/appVersionManagementStore"
import { useRouter } from "vue-router"

const phone = inject("isPhone") as Ref<boolean>

const router = useRouter()
const gotoLinkThatIsBeingWorkedOn = async () => {
  await router.push({
    name: "offer.preview.raw",
    params: { id: "127a22a7-f3eb-4455-ac5d-5bdc89dfec4a" },
  })
}

const versionStore = useVersionStore()
versionStore.initVersionCheck("/version.json", 1000 * 60 * 1)
</script>
