<template>
  <div
    class="flex min-h-[64px] min-w-[64px] items-center justify-center rounded-xl border hover:bg-1-hover"
    :class="[{ 'w-full': !hasPhotos, 'mr-1': hasPhotos }]"
    @click="triggerFileInput"
  >
    <label class="flex items-center">
      <CameraIcon class="w-7 text-primary" :class="[{ 'mr-2': !hasPhotos }]" />
      <p v-if="!hasPhotos" class="text-label-bold-2 text-color-link-static">
        {{ $t("post.item.photos.add_photo") }}
      </p>
    </label>
  </div>
</template>

<script setup lang="ts">
import { useImageStore } from "@/stores/imageStore"
import { usePostStore } from "@/stores/postStore"
import { CameraIcon } from "@heroicons/vue/24/outline"

const postStore = usePostStore()
const fileStore = useFileStore()
const imageStore = useImageStore()
const hasPhotos = computed(() => postStore.post?.pictures.length !== 0)

async function triggerFileInput() {
  const result = await fileStore.retrieveImage()
  if (result) {
    await imageStore.handleImageFromPath(fileStore.fileLocalUrl)
  }
}
</script>
