<template>
  <nav class="border-gray flex w-full items-center border-b p-5 print:hidden">
    <div class="flex w-64 items-center">
      <router-link :to="{ name: 'quotes' }">
        <img class="ml-4 h-6" src="/img/tredco.svg" alt="Tredco" />
      </router-link>
    </div>
    <span class="grow"></span>
    <TenantSelector v-if="!isPhone" />
    <UserDropdown class="w-32" />
  </nav>
</template>
<script setup lang="ts">
const isPhone = inject("isPhone") as Ref<boolean>
</script>
