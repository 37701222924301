<template>
  <div class="grid grid-cols-3 rounded-xl bg-1-static p-4">
    <div :class="'col-span-2'">
      <p class="text-label-bold-1 text-color-label-1">{{ company.navn }}</p>
      <p class="truncate text-label-2 text-color-label-2">{{ address }}</p>

      <p class="text-label-3 text-color-label-3">
        {{ orgNo }}
      </p>
    </div>
    <div class="flex items-center justify-center">
      <Button size="small" type="secondary">{{ $t("select") }}</Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BrregCompany } from "@/types"

const { t } = useI18n()

type Props = {
  company: BrregCompany
}
const props = defineProps<Props>()

const address = computed(() => {
  const components = [
    props.company.forretningsadresse?.adresse[0],
    props.company.forretningsadresse?.poststed,
    props.company.forretningsadresse?.kommune,
  ]
  const filteredComponents = components.filter(Boolean) // removes falsy values
  return filteredComponents.join(", ")
})

const orgNo = computed(() => {
  if (!props.company.organisasjonsnummer) return ""

  return `${t("org_number_abbrev")}: ${props.company.organisasjonsnummer}`
})
</script>
