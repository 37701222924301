import { defineStore } from "pinia"
import { ref, computed } from "vue"
import type { Project, QuoteStatuses } from "@/types" // Assuming you have a Project type defined
import useFirebase from "@/api/firestore/useFirestore"

export const useProjectController = defineStore("projectController", () => {
  const { loadCollection } = useFirebase()
  const globalStore = useGlobalStore()
  // State
  const projects = ref<Project[]>([])
  const currentProject = ref<Project | null>(null)
  const isLoading = ref(false)

  // Getters
  const projectCount = computed(() => projects.value.length)

  // Actions
  function setCurrentProject(project: Project | null) {
    currentProject.value = project
  }

  async function fetchProjects() {
    isLoading.value = true
    try {
      // Implement API call to fetch projects
      // projects.value = await api.getProjects()
    } catch (error) {
      console.error("Failed to fetch projects:", error)
      // Handle error (e.g., set an error state, show a notification)
    } finally {
      isLoading.value = false
    }
  }

  async function createProject(project: Partial<Project>) {
    isLoading.value = true
    try {
      // Implement API call to create a project
      // const newProject = await api.createProject(project)
      // projects.value.push(newProject)
    } catch (error) {
      console.error("Failed to create project:", error)
      // Handle error
    } finally {
      isLoading.value = false
    }
  }

  async function updateProject(projectId: string, updates: Partial<Project>) {
    isLoading.value = true
    try {
      // Implement API call to update a project
      // const updatedProject = await api.updateProject(projectId, updates)
      // Update the project in the projects array
    } catch (error) {
      console.error("Failed to update project:", error)
      // Handle error
    } finally {
      isLoading.value = false
    }
  }

  async function deleteProject(projectId: string) {
    isLoading.value = true
    try {
      // Implement API call to delete a project
      // await api.deleteProject(projectId)
      // Remove the project from the projects array
      projects.value = projects.value.filter(p => p.id !== projectId)
    } catch (error) {
      console.error("Failed to delete project:", error)
      // Handle error
    } finally {
      isLoading.value = false
    }
  }

  async function updateProjectFilter(statuses: QuoteStatuses[]) {
    isLoading.value = true
    try {
      if (statuses.length > 0) {
        await loadCollection("projects", globalStore.tenant.id, { statuses })
      } else {
        await loadCollection("projects", globalStore.tenant.id)
      }
    } catch (error) {
      console.error("Failed to update statuses:", error)
    } finally {
      isLoading.value = false
    }
  }

  return {
    // State
    projects,
    currentProject,
    isLoading,

    // Getters
    projectCount,

    // Actions
    setCurrentProject,
    fetchProjects,
    createProject,
    updateProject,
    deleteProject,
    updateProjectFilter,
  }
})
