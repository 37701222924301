<template>
  <HeaderOnboarding>
    <div class="mb-6 flex flex-col">
      <h2 class="text-heading-3 text-color-label-1">
        {{ $t("onboarding.account_exists") }}
      </h2>
      <div class="text-label-2 text-color-label-1">
        <p class="mt-2">
          {{ $t("onboarding.account_exists_message", [globalStore.user.email]) }}
        </p>
      </div>
    </div>
    <div class="text-label-bold-1 text-color-label-1">
      <p class="my-2">{{ $t("onboarding.account_exists_update-account") }}</p>
    </div>
    <LimitedOptions
      :options="options"
      type="navigation"
      @selected-id="handleSelectedId"
    />
    <Button
      id="submit-code"
      class="mt-8 w-full"
      :loading="loading"
      size="large"
      submit
      @click="addNewAccountToUser"
      >{{ $t("onboarding.account_exists_add_new_account_to_this_user") }}</Button
    >
    <Button
      id="submit-code"
      class="mt-3 w-full"
      type="secondary"
      size="large"
      submit
      @click="() => $router.push({ name: 'quotes' })"
      >{{ $t("onboarding.account_exists_back_to_login") }}</Button
    >
  </HeaderOnboarding>
</template>

<script setup lang="ts">
import { useOnboardingStore } from "@/stores/onboardingStore"
import { Toaster } from "@/types"

const globalStore = useGlobalStore()
const onboardingStore = useOnboardingStore()
const toast = inject<Toaster>("toast") as Toaster
const options = ref<{ label: string; id: string; selected: boolean }[]>([])

watchEffect(() => {
  options.value = []
  for (const employee of globalStore.user.tenants) {
    const t = employee.tenant
    options.value.push({
      label: t.name,
      id: t.id,
      selected: false,
    })
  }
})

const loading = ref(false)
async function addNewAccountToUser() {
  loading.value = true
  const response = (await api.callN8nWorkflow({
    tenant_id: "not-allocated-yet",
    path: "/user-tenant",
    action: "existing-user-new-tenant",
    user_id: globalStore.user.id,
  })) as { data: { tenant_id: string } }

  if (response?.data?.tenant_id) {
    await globalStore.hydrate()
    await globalStore.setTenant(response.data.tenant_id)
    onboardingStore.status = "company"
  } else {
    console.log("Error adding new account to user")
    toast.error("Something went wrong, please try again later.")
  }
  loading.value = false
}

async function handleSelectedId(id: string) {
  await globalStore.setTenant(id)
  onboardingStore.currency = globalStore.tenant.currency
  onboardingStore.status = "company"
}
</script>
