<template>
  <div>
    <div
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div class="animate-zoom-in m-2 rounded-2xl bg-white p-6">
        <h2 class="mb-4 mr-7 text-lg font-semibold">{{ $t("confirm_signout") }}</h2>
        <div class="flex justify-end">
          <button class="button-secondary mr-7 rounded-full px-4 py-2" @click="cancel">
            {{ $t("cancel") }}
          </button>
          <button
            class="button-primary mr-2 w-36 rounded-full px-4 py-2 text-white"
            @click="signOut"
          >
            {{ $t("sign_out") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  cancelAction: Function,
  signOutAction: Function,
})

function cancel() {
  if (props.cancelAction) {
    props.cancelAction()
  }
}

function signOut() {
  if (props.signOutAction) {
    props.signOutAction()
  }
}
</script>

<style scoped>
.button-primary {
  background-color: #0055ffff;
  color: #ffffffff;
}
.button-secondary {
  color: #0055ff;
}

@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-zoom-in {
  animation: zoom-in 0.3s;
}
</style>
