<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.45115 14.7405C6.01288 15.2475 6.62483 15.7013 7.27842 16.0956L5.54305 17.7513C5.40237 17.8902 5.32453 18.0764 5.32629 18.2696C5.32805 18.4628 5.40927 18.6476 5.55246 18.7842C5.69565 18.9208 5.88936 18.9983 6.09185 19C6.29435 19.0017 6.48943 18.9274 6.63509 18.7932L11.5994 14.0567L19.7642 6.26684C19.838 6.19887 19.8968 6.11757 19.9373 6.02767C19.9778 5.93777 19.9991 5.84108 20 5.74324C20.0009 5.64541 19.9813 5.54838 19.9425 5.45783C19.9037 5.36727 19.8463 5.285 19.7738 5.21582C19.7013 5.14663 19.6151 5.09192 19.5201 5.05487C19.4252 5.01782 19.3235 4.99918 19.221 5.00003C19.1184 5.00088 19.0171 5.0212 18.9229 5.05982C18.8287 5.09844 18.7434 5.15457 18.6722 5.22495L16.855 6.95874C15.9874 6.47479 15.0448 6.12536 14.0623 5.92348C13.472 5.80579 12.8706 5.74628 12.2675 5.7459C6.47522 5.7459 3.23619 11.0512 3.10103 11.2759C3.03482 11.3869 3 11.5125 3 11.6403C3 11.768 3.03482 11.8936 3.10103 12.0046C3.74793 13.016 4.53885 13.9367 5.45115 14.7405ZM10.7229 11.6406C10.7229 11.2498 10.8856 10.875 11.1753 10.5986C11.465 10.3222 11.8578 10.1669 12.2675 10.1669C12.5975 10.168 12.9182 10.2714 13.1811 10.4617L11.0318 12.5123C10.8324 12.2615 10.7239 11.9555 10.7229 11.6406ZM12.2675 7.21958C12.7655 7.21998 13.2621 7.26936 13.7495 7.36695C14.4346 7.50728 15.0968 7.73505 15.7181 8.04411L14.2847 9.41095C13.726 8.94588 13.009 8.69077 12.2675 8.69326C11.4482 8.69326 10.6624 9.00379 10.0831 9.55653C9.50375 10.1093 9.17828 10.8589 9.17828 11.6406C9.17567 12.3481 9.44305 13.0322 9.9305 13.5653L8.40134 15.0242C7.71702 14.6429 7.08151 14.1869 6.50766 13.6655C5.82488 13.0567 5.2149 12.3776 4.68889 11.6406C5.4859 10.5228 8.18664 7.21958 12.2675 7.21958Z"
      fill="currentColor"
    />
    <path
      d="M11.9873 16.4235C11.925 16.4235 11.8656 16.4235 11.8056 16.4188C11.6089 16.4038 11.4145 16.4705 11.2643 16.6046C11.1142 16.7387 11.0203 16.9294 11.0029 17.1356C10.9856 17.3417 11.0461 17.5467 11.1716 17.7064C11.297 17.8661 11.4773 17.9676 11.6734 17.989C11.7785 17.9992 11.8822 18 11.9873 18C17.6075 18 20.7687 12.3247 20.9009 12.0882C20.9658 11.9691 21 11.8342 21 11.6969C21 11.5596 20.9658 11.4247 20.9009 11.3055C20.4686 10.5584 19.9653 9.85904 19.3988 9.21826C19.2614 9.08056 19.0794 9.00259 18.8895 9.00006C18.6996 8.99754 18.5158 9.07064 18.3751 9.20465C18.2345 9.33866 18.1475 9.52362 18.1316 9.72228C18.1157 9.92094 18.1721 10.1185 18.2895 10.2753C18.6762 10.7216 19.0329 11.1957 19.3567 11.6941C18.5779 12.8914 15.9446 16.4235 11.9873 16.4235Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>
