<template>
  <div>
    <Button type="secondary" size="large" class="w-full" @click="selectFile">
      <p>{{ t("quote.import_from_spark_blueprint") }}</p>
    </Button>
    <input
      ref="fileInput"
      type="file"
      class="hidden"
      accept=".xml"
      @change="handleFileChange"
    />
  </div>
</template>

<script setup lang="ts">
import { xml2json } from "xml-js"

const emit = defineEmits(["update"])
const fileInput = ref<HTMLInputElement | null>(null)

const selectFile = (): void => {
  fileInput.value?.click()
}

const handleFileChange = (event: Event): void => {
  const target = event.target as HTMLInputElement
  const file = target.files?.[0]
  if (file) {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.result) {
        const jsonResult = xml2json(reader.result as string, { compact: true, spaces: 2 })
        const cleanJson = cleanJsonObject(JSON.parse(jsonResult))
        emitToParent(cleanJson)
        target.value = ""
      }
    }
    reader.readAsText(file)
  }
}

const emitToParent = (json: any): void => {
  emit("update", json)
}
</script>

<style scoped>
.hidden {
  display: none;
}
</style>
