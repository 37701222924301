<template>
  <BottomActionSlot>
    <div class="mb-4 flex w-full justify-between">
      <p class="text-label-1 text-color-label-2">{{ $t("quote.total_excl_tax") }}</p>
      <p class="text-label-1 text-color-label-1">
        {{ formatCurrency(props.grandTotal) }}
      </p>
    </div>

    <div class="grid grid-cols-2 gap-3">
      <QuoteStatus
        :current-status="projectStore.project.quote_status"
        @change-status="updateQuoteStatus"
      />
      <Button
        :loading="loading"
        type="primary"
        class="w-full"
        size="large"
        @click="goToQuoteSettings"
        >{{ $t("quote.continue") }}
      </Button>
    </div>
  </BottomActionSlot>
</template>

<script setup lang="ts">
import router from "@/router"
import { useProjectStore } from "@/stores/projectStore"
import { QuoteStatuses } from "@/types"
import { formatCurrency } from "@/utils/companyUtils"

type Props = {
  grandTotal: number
  loading: boolean
}

const projectStore = useProjectStore()

const props = withDefaults(defineProps<Props>(), {
  grandTotal: 0,
  loading: false,
})

async function updateQuoteStatus(status: QuoteStatuses) {
  projectStore.project.quote_status = status
  await projectStore.updateProject()
}

async function goToQuoteSettings() {
  await router.push({
    name: "quote.settings",
    params: {
      quoteId: projectStore.project.id,
    },
  })
}
</script>
