import { CountrySetting, getCountriesConfig } from "@/config/countries"

interface IPInfo {
  ip: string
  hostname: string
  city: string
  region: string
  country: string
  loc: string
  org: string
  postal: string
  timezone: string
  readme: string
}

const example: IPInfo = {
  ip: "88.89.39.127",
  hostname: "ti0188a330-0379.bb.online.no",
  city: "Ås",
  region: "Akershus",
  country: "NO",
  loc: "59.6647,10.7947",
  org: "AS2119 Telenor Norge AS",
  postal: "1430",
  timezone: "Europe/Oslo",
  readme: "https://ipinfo.io/missingauth",
}

export async function getLanguageBasedOnIP(): Promise<CountrySetting> {
  try {
    const response = await fetch("https://ipinfo.io/json")
    const data = (await response.json()) as IPInfo
    console.log("Retrieved country data based on IP", data)
    const countryCode = data.country

    const countries = getCountriesConfig().map

    let country = countries.get(countryCode)
    if (!country) {
      country = countries.get("CA")
    }

    if (country) {
      return country
    } else {
      throw new Error("Country not found")
    }
  } catch (error) {
    console.error("Failed to fetch IP information", error)
    const country = getCountriesConfig().array[0]
    return country.settings
  }
}
