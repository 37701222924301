<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.5118 5.86702L8.0337 15.3452L3 10.3115L4.17851 9.13295L8.0337 12.9881L16.3333 4.68851L17.5118 5.86702Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>
