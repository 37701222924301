<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1558 3.57095C11.5527 2.80968 12.4473 2.80968 12.8442 3.57095L18.8359 15.0629C19.2709 15.8972 18.7903 17 17.9917 17H6.00828C5.20974 17 4.72913 15.8972 5.1641 15.0629L11.1558 3.57095Z"
      fill="black"
      fill-opacity="0.1"
    />
  </svg>
</template>

<script setup lang="ts"></script>
