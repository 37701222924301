<template>
  <div class="bg-1-static">
    <PostHeaderMobile />
    <div class="pt-4 post-card">
      <GrammarCheckWrapper v-model="postStore.post.name" field-id="post-title">
        <TextArea
          id="post-title"
          v-model="postStore.post.name"
          :name="$t('post_labels.title')"
          class="mb-4"
          :rows="2"
          :select-content="postStore.status === 'new'"
          @blur="postStore.updateActivePost()"
        />
      </GrammarCheckWrapper>
      <GrammarCheckWrapper
        v-model="postStore.post.description"
        field-id="post-description"
        :available-options="['grammar', 'clarity', 'formal', 'structure']"
      >
        <div class="relative">
          <TextArea
            id="post-description"
            v-model="postStore.post.description"
            :name="$t('post_labels.description')"
            :rows="4"
            @blur="postStore.updateActivePost()"
          />
          <Bob
            class="absolute top-0 right-3"
            :context="bobContext"
            @done="updateDescription"
            @streaming="updateDescriptionDisplayText"
          />
        </div>
      </GrammarCheckWrapper>
      <PostGallery />
      <div class="flex mb-4">
        <TextFieldVal
          v-model="postStore.post.qty"
          type="number"
          rules="decimal"
          text-size="normal"
          :label="$t('post_labels.quantity')"
          name="qty"
          class="w-1/4"
          :select-content="true"
          @blur="postStore.updateActivePost()"
        />

        <SelectBox
          translation-path="units"
          class="w-1/4 mx-2"
          :selected-value="selectedUnit"
          :label="$t('post_labels.unit')"
          @select="updateUnit"
        />

        <TextFieldVal
          v-model="postStore.post.unit_price"
          text-size="normal"
          type="number"
          rules="decimal"
          class="w-2/4"
          :label="`${$t('post_labels.unit_price')} ${$t(`units.${selectedUnit}`)}`"
          name="unit_price"
          :select-content="true"
          :suffix="countryInfo?.currencyCode"
          @blur="postStore.updateActivePost()"
        />
      </div>
      <div class="flex justify-between w-full">
        <p class="text-label-1 text-color-label-2">{{ $t("quote.total_excl_tax") }}</p>
        <p class="mr-3 text-label-1 text-color-label-1">
          {{
            formatCurrency(Number(postStore.post.unit_price) * Number(postStore.post.qty))
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const postStore = usePostStore()
const globalStore = useGlobalStore()
provide<boolean>("editing", true)

const countryInfo = getCountriesConfig().map.get(globalStore.tenant.country_code)

const bobContext = computed(() => ({
  title: postStore.post.name,
  field: "quote_line_item_description",
  language: window.localStorage.getItem("tredco-app-language"),
}))

const selectedUnit = ref(postStore.post.unit)

async function updateUnit(unit: string) {
  selectedUnit.value = unit
  postStore.post.unit = unit
  await postStore.updateActivePost()
}

const updateDescriptionDisplayText = (description: string) => {
  if (!postStore.post) return
  postStore.post.description = description
}

const updateDescription = async (description: string) => {
  if (!postStore.post) return
  postStore.post.description = description
  await postStore.updateActivePost()
}
</script>
