import { Project, Tenant } from "@/types"

export function calculateGrandTotal(project: Project | null) {
  if (!project?.phases?.length) return 0
  let grandTotal = 0
  for (const phase of project.phases) {
    if (!phase.posts?.length) continue
    let phaseTotal = 0
    for (const post of phase.posts) {
      phaseTotal += Number(post.qty) * Number(post.unit_price)
    }
    grandTotal += phaseTotal
  }
  return grandTotal
}

export function calculateVat(
  project: Project | null,
  tenant: Pick<Tenant, "vat_registered" | "$countrySetting">,
): number {
  if (!project || !tenant.vat_registered || !tenant.$countrySetting) {
    console.log(
      "No project or not vat registered",
      project,
      tenant.vat_registered,
      tenant.$countrySetting,
    )
    return 0
  }

  const vatRate = tenant.$countrySetting.vatRate || 0
  return (calculateGrandTotal(project) - Number(project.discount_amount ?? 0)) * vatRate
}

export function calculateTotalIncVat(
  project: Project | null,
  tenant: Pick<Tenant, "vat_registered" | "$countrySetting">,
): number {
  if (!project) return 0
  const subtotal = calculateGrandTotal(project)
  const vat = calculateVat(project, tenant)
  const discount = Number(project.discount_amount ?? 0)
  const total = subtotal + vat - discount
  return total
}
