<template>
  <div>
    <MobileDialog
      :loading="false"
      :sub-title="deleteStore.message ?? t('confirm_generic')"
      :show="showModelToConfirmDelete"
      @y="deleteItem"
      @n="handleCancel"
    />
  </div>
</template>

<script setup lang="ts">
import api from "@/api"
import { t } from "@/localization/i18n"
import { DirectusContext, Schema, Toaster } from "@/types"
import { useDeleteStore } from "@/stores/useDeleteStore"

const deleteStore = useDeleteStore()
const showModelToConfirmDelete = ref(false)
const toast = inject<Toaster>("toast")

async function deleteItem() {
  const item = deleteStore.itemToDelete
  if (!item?.collection || !item?.id) {
    console.error("Cannot delete item, some values are missing")
    toast?.error(t("toast.error"))
    showModelToConfirmDelete.value = false
    return
  }

  const context: DirectusContext = {
    collection: item.collection,
    id: item.id,
    data: {
      status: "archived",
    },
  }
  await api.updateGeneric(context)
  showModelToConfirmDelete.value = false
  deleteStore.itemDeleted = true
  deleteStore.clearItemToDelete()
}

function handleCancel() {
  showModelToConfirmDelete.value = false
  deleteStore.clearItemToDelete()
}

watch(
  () => deleteStore.itemToDelete,
  async newValue => {
    if (newValue?.id) {
      if (deleteStore.confirmBeforeDelete) {
        showModelToConfirmDelete.value = true
      } else {
        await deleteItem()
      }
    }
  },
)
</script>
