<template>
  <div ref="container" class="flex justify-between pb-[9.8mm]">
    <img
      v-if="logoId"
      :src="getFullImageUrl(logoId)"
      :class="biggerLogo ? 'h-[14mm]' : 'h-[7mm]'"
      @load="() => (loading = false)"
    />
    <div v-else class="text-label-bold-2" :style="`color: ${colors.primary};`">
      {{ tenant.name }}
    </div>
    <div
      v-if="label"
      class="text-heading-2 opacity-40"
      :style="`color: ${colors.primary};`"
    >
      {{ label }}
    </div>
    <div v-else class="page-numbers text-caption-2"></div>
  </div>
</template>

<script setup lang="ts">
import { Color, PdfTenant } from "@/pdf-generator/types"
import { computed, defineEmits, defineProps, inject, ref, watch } from "vue"
import { primaryKey } from "@/utils/primaryKey"

const props = defineProps<{
  tenant: PdfTenant
  colors: Color
  biggerLogo?: boolean
  label?: string
}>()

const assetUrl = inject("assetUrl") as (assetId: string) => string

const logoId = computed(() => primaryKey(props.tenant.logo || ""))

const loading = ref(false)
function getFullImageUrl(imageId: string) {
  loading.value = true
  return assetUrl(imageId)
}

const container = ref<HTMLElement | null>(null)
const emit = defineEmits<{ ready: [val: number] }>()

function checkReady() {
  if (!container.value) return
  if (loading.value) return
  emit("ready", container.value.clientHeight)
}

watch([loading, container], checkReady, { deep: true })
</script>
<style>
#pdf-quote-document {
  counter-reset: currentPage;
}

#pdf-quote-document > .page .page-numbers:before {
  counter-increment: currentPage;
  content: counter(currentPage);
}
</style>
