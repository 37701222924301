const keys = {
  localFB: {
    apiBaseBath: "http://localhost:8055",
    envName: "local-front-end",
    mixPanelToken: "",
    sentryDns: "",
    isProduction: false,
    generatePdfLocally: import.meta.env.VITE_LOCAL_PDF_GENERATION ?? "function",
    sendQuoteEmailUrl:
      "http://localhost:8055/flows/trigger/994e6217-99af-4201-ab09-aaa9070009c7",
    n8nWorkflowUrl:
      "http://localhost:8055/flows/trigger/2b2df790-90f3-477b-b3f5-6de41675d7d1",
    generatePostDescriptionUrl:
      "http://localhost:8055/flows/trigger/317709f2-811e-42f4-8fe6-93782f3b3e05",
    generatePdfUrl: "https://pdf-bi4ysfnf2q-lz.a.run.app",
  },
  localF: {
    apiBaseBath: "https://service.tredco.app",
    envName: "local-front-and-backend",
    mixPanelToken: "",
    sentryDns: "",
    isProduction: false,
    generatePdfLocally: import.meta.env.VITE_LOCAL_PDF_GENERATION ?? "function",
    sendQuoteEmailUrl:
      "https://service.tredco.app/flows/trigger/994e6217-99af-4201-ab09-aaa9070009c7",
    n8nWorkflowUrl:
      "https://service.tredco.app/flows/trigger/2b2df790-90f3-477b-b3f5-6de41675d7d1",
    generatePostDescriptionUrl:
      "https://service.tredco.app/flows/trigger/317709f2-811e-42f4-8fe6-93782f3b3e05",
    generatePdfUrl: "https://pdf-bi4ysfnf2q-lz.a.run.app",
  },
  prod: {
    apiBaseBath: "https://service.tredco.app",
    mixPanelToken: "c367aad41fd522f169439565ee3717d8",
    sentryDns:
      "https://8bea188addcd430da031babc1a9be2c8@o4504887047159808.ingest.sentry.io/4504887052402688",
    envName: "prod",
    isProduction: true,
    generatePdfLocally: import.meta.env.VITE_LOCAL_PDF_GENERATION ?? "function",
    sendQuoteEmailUrl:
      "https://service.tredco.app/flows/trigger/994e6217-99af-4201-ab09-aaa9070009c7",
    n8nWorkflowUrl:
      "https://service.tredco.app/flows/trigger/2b2df790-90f3-477b-b3f5-6de41675d7d1",
    generatePostDescriptionUrl:
      "https://service.tredco.app/flows/trigger/317709f2-811e-42f4-8fe6-93782f3b3e05",
    generatePdfUrl: "https://pdf-bi4ysfnf2q-lz.a.run.app",
  },
}

function determineConfigBasedOnEnvironment(): typeof keys.localFB {
  let config = keys.prod

  if (window.location.host === "localhost:3000") {
    config = keys.localF
  } else if (window.location.host === "localhost:3001") {
    config = keys.localFB
  }

  if (!config.isProduction && config.generatePdfLocally === "web") {
    config.generatePdfUrl = "http://localhost:3002"
  } else if (!config.isProduction && config.generatePdfLocally === "function") {
    config.generatePdfUrl = "http://localhost:8080"
  }

  return config
}

export const config = determineConfigBasedOnEnvironment()
