<template>
  <DeleteDirectusItem />
  <FullStory />
  <Pendo />
  <RouterView />
  <NewBuildStatus />
</template>
<script setup lang="ts">
import FullStory from "@/components/shared/monitoring/FullStory.vue"
import NewBuildStatus from "@/components/shared/monitoring/NewBuildStatus.vue"
import Pendo from "@/components/shared/monitoring/Pendo.vue"
import { isPhone } from "@/composables/isPhone"
import useFirebase from "@/api/firestore/useFirestore"
import { CountrySetting } from "@/config/countries"
import { useRoute } from "vue-router"

const phone = ref<boolean>(false)

function updateRootFontSize(isMobile: boolean) {
  // Set 16px as base for desktop, 14px for mobile
  const rootFontSize = isMobile ? "16px" : "16px"
  document.documentElement.style.fontSize = rootFontSize
}

phone.value = isPhone()
const bgColorsInverted = ref<boolean>(false)
provide("isPhone", phone)
provide("bgColorsInverted", bgColorsInverted)

watch(
  phone,
  () => {
    phone.value = isPhone()
    updateRootFontSize(phone.value)

    if (phone.value) {
      bgColorsInverted.value = false
    } else {
      bgColorsInverted.value = true
    }
  },
  { immediate: true },
)

watch(
  bgColorsInverted,
  () => {
    const bg1 = "#ffffffff"
    const bg2 = "#edf0f3ff"
    if (!bgColorsInverted.value) {
      document.documentElement.style.setProperty("--color-dynamic-1", bg1)
      document.documentElement.style.setProperty("--color-dynamic-2", bg2)
    } else {
      document.documentElement.style.setProperty("--color-dynamic-1", bg2)
      document.documentElement.style.setProperty("--color-dynamic-2", bg1)
    }
  },
  { immediate: true },
)

window.addEventListener("resize", () => {
  phone.value = isPhone()
  updateRootFontSize(phone.value)
})

const globalStore = useGlobalStore()
const countrySetting = ref<CountrySetting | undefined>(undefined)
const route = useRoute()
const { projectId } = useFirebase()
provide("countrySetting", countrySetting)
watch(
  () => globalStore.tenant,
  tenant => {
    countrySetting.value = undefined
    if (!tenant) return
    countrySetting.value = tenant.$countrySetting
  },
)

watch(
  () => route.params.quoteId,
  newProjectId => {
    projectId.value = typeof newProjectId === "string" ? newProjectId : null
  },
  { immediate: true },
)
</script>
