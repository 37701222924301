<template>
  <div class="w-10 aspect-square">
    <RoundImage
      :src="logo"
      :allow-edit="allowEdit"
      :border-color="globalStore.tenant.invoice_color"
      :update-image-contex="directusContext"
      @image-updated="refreshProfileImage"
    >
      <p
        class="flex items-center justify-center w-full h-full text-label-1 text-color-label-2"
        :style="`color: ${globalStore.tenant.invoice_color}`"
      >
        {{ initials }}
      </p>
    </RoundImage>
  </div>
</template>

<script setup lang="ts">
import { DirectusContext } from "@/types"

type Props = {
  allowEdit?: boolean
}
withDefaults(defineProps<Props>(), {
  allowEdit: true,
})

const globalStore = useGlobalStore()
const tenantStore = useTenantStore()
const logo = ref<string>("")

const initials = computed(() => {
  const firstInitial = (globalStore.user?.first_name ?? "").charAt(0)
  const lastInitial = (globalStore.user?.last_name ?? "").charAt(0)
  return (firstInitial + lastInitial).toUpperCase()
})

watchEffect(async () => {
  if (!globalStore.user?.avatar) {
    return
  }
  logo.value = `${api.client.url}/assets/${globalStore.user?.avatar}?access_token=${globalStore.token}&key=thumbnail`
})

const directusContext: DirectusContext = {
  collection: "directus_users",
  id: globalStore.user?.id,
  property: "avatar",
}

async function refreshProfileImage() {
  await tenantStore.refreshTenant()
}
</script>
