<template>
  <div
    class="grow desktop:m-4 desktop:rounded-lg"
    :class="{
      'bg-2-static': !formPage,
      'bg-1-static': formPage,
      'p-0': !outsidePadding,
      'p-4': outsidePadding,
    }"
  >
    <div
      class="sticky top-0 z-10 py-4"
      :class="{ 'bg-2-static': !formPage, 'bg-1-static': formPage }"
    >
      <slot name="header"></slot>
    </div>
    <div>
      <slot></slot>
    </div>
    <footer v-if="mobile">
      <BottomActionSlot>
        <slot name="footer-mobile"></slot>
      </BottomActionSlot>
    </footer>
  </div>
</template>

<script setup lang="ts">
const mobile = inject("isPhone") as Ref<boolean>

defineProps({
  formPage: {
    type: Boolean,
    default: false,
  },
  outsidePadding: {
    type: Boolean,
    default: true,
  },
})
</script>

<style lang="scss" scoped></style>
