import api from "@/api"
import { BrregCompany, Customer, NewCustomer } from "@/types"
import initials from "@/types/initialValues"
import { readItems } from "@directus/sdk"
import { defineStore } from "pinia"

interface CustomerState {
  customers: Customer[]
  filtered: Customer[]
  companies: BrregCompany[]
  customer: NewCustomer
  loading: boolean
  query: string
  brregCompanySelected: boolean
  status: "new" | "edit"
  globalStore: ReturnType<typeof useGlobalStore>
}

let lastFetched: Date | null = null
let lastTenant: string | null = null
export const useCustomerStore = defineStore("customer", {
  state: (): CustomerState => ({
    customers: [],
    filtered: [],
    customer: structuredClone(initials.emptyCustomerValue),
    companies: [],
    loading: false,
    query: "",
    brregCompanySelected: false,
    status: "new",
    globalStore: useGlobalStore(),
  }),
  actions: {
    async fetch(customerId: string) {
      try {
        this.customer = (await api.customers.getCustomer(customerId)) as NewCustomer
      } catch (error) {
        console.error("🚀 - file: Offers.vue:74 - error:", error)
      }
    },
    async search(query: string) {
      // If everything exists locally
      if (true && this.customers.length) {
        const q = query.trim().toLocaleLowerCase()
        this.filtered = this.customers.filter(customer => {
          return (
            customer.name.toLowerCase().includes(q) ||
            customer.city?.toLowerCase().includes(q)
          )
        })
        return
      }
      this.loading = true
      const result = await api.client.request(
        readItems("customers", {
          limit: 30,
          page: 1,
          meta: "*",
          filter: {
            _or: [
              {
                name: {
                  _icontains: query,
                },
              },
              {
                city: {
                  _icontains: query,
                },
              },
            ],
          },
          sort: "-date_created",
        }),
      )
      if (result) {
        this.filtered = result as Customer[]
      }

      this.loading = false
    },
    async init(force = false) {
      const { tenant } = this.globalStore
      if (!tenant) {
        this.customers = []
        return
      }
      const filter = {}
      if (force || lastTenant !== tenant.id) {
        lastFetched = null
        lastTenant = tenant.id
      } else if (lastFetched) {
        filter.date_updated = {
          _gte: lastFetched.toISOString(),
        }
      }
      if (!lastFetched) {
        this.loading = true
      }
      filter.tenant = { _eq: tenant.id }
      lastFetched = new Date()
      const data = await api.client.request(
        readItems("customers", {
          limit: 1000,
          page: 1,
          meta: "*",
          filter,
          sort: "-date_created",
        }),
      )

      for (const row of data) {
        const existing = this.customers.find(c => c.id === row.id)
        if (existing) {
          Object.assign(existing, row)
        } else {
          this.customers.push(row)
        }
      }
      this.loading = false
    },
    searchApiUrl(name: string) {
      return `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${name}&konkurs=false&size=10`
    },
    getApiUrl(orgNumber: string) {
      return `https://data.brreg.no/enhetsregisteret/api/enheter?organisasjonsnummer=${orgNumber}`
    },
    async searchBrreg(searchQuery: string, isPending: Ref<boolean>) {
      if (searchQuery.length >= 3) {
        isPending.value = true
        const result: BrregCompany[] =
          (await (await fetch(this.searchApiUrl(searchQuery))).json())?._embedded
            ?.enheter ?? []
        this.companies = result.map(item => ({
          ...item,
          navn: this.convertCompanyNameFormat(item.navn),
        }))
        isPending.value = false
      }
    },
    handleSelect(companyInfo: BrregCompany) {
      this.customer.company_registration_number = `${companyInfo.organisasjonsnummer}`
      this.customer.name = this.convertCompanyNameFormat(companyInfo.navn)
      this.customer.street_address = companyInfo.forretningsadresse.adresse[0]
      this.customer.country_code = companyInfo.forretningsadresse.land
      this.customer.billing_country_code = companyInfo.forretningsadresse.land
      this.customer.zip_code = companyInfo.forretningsadresse.postnummer
      this.customer.city = this.convertCityNameFormat(
        companyInfo.forretningsadresse.poststed,
      )
      this.customer.province = companyInfo.forretningsadresse.kommune
      this.customer.billing_province = companyInfo.forretningsadresse.kommune
      this.customer.municipality = this.convertCityNameFormat(
        companyInfo.forretningsadresse.kommune,
      )

      this.customer.billing_zip_code = companyInfo.forretningsadresse.postnummer
      this.customer.billing_city = this.convertCityNameFormat(
        companyInfo.forretningsadresse.poststed,
      )
      this.customer.billing_municipality = this.convertCityNameFormat(
        companyInfo.forretningsadresse.kommune,
      )
      this.customer.type = "company"
      this.customer.email = ""
      this.customer.phone_number = ""

      this.brregCompanySelected = true
    },
    async create(): Promise<Customer> {
      try {
        const result = (await api.customers.createCustomer(this.customer)) as Customer
        this.customers.push(result)
        this.customer = structuredClone(initials.emptyCustomerValue)
        return result
      } catch (error) {
        console.error("🚀 - file: Offers.vue:74 - error:", error)
        throw error
      }
    },
    capitalizeFirstLetter(text: string): string {
      if (text === "") return text
      return text[0].toUpperCase() + text.substring(1).toLowerCase()
    },
    convertCompanyNameFormat(name: string): string {
      const nameFragments = name.split(" ").map(this.capitalizeFirstLetter)
      const lastIndex = nameFragments.length - 1
      if (["Asa", "As"].includes(nameFragments[`${lastIndex}`])) {
        nameFragments[`${lastIndex}`] = nameFragments[`${lastIndex}`].toUpperCase()
      }
      return nameFragments.join(" ")
    },
    convertCityNameFormat(name: string): string {
      const nameFragments = name.split(" ").map((word: string, index: number) => {
        return word.length > 1 || index === 0
          ? this.capitalizeFirstLetter(word)
          : word.toLowerCase()
      })
      return nameFragments.join(" ").trim()
    },
    async loadCustomer(id: string) {
      this.customer = await api.customers.getCustomer(id)
    },
    resetCustomer() {
      this.query = ""
      this.brregCompanySelected = false
      this.customer = structuredClone(initials.emptyCustomerValue)
      lastFetched = null
      lastTenant = null
    },
  },
  getters: {
    showBrregSearchBox(state): boolean {
      return (
        state.brregCompanySelected === false &&
        state.customer.type === "company" &&
        state.status === "new" &&
        state.globalStore.tenant?.currency === "NOK"
      )
    },
  },
})
