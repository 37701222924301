<template>
  <div class="w-full p-0 m-0 h-dvh bg-1-static">
    <div v-if="!isRetrieving">
      <div class="flex items-center justify-between pl-4 mb-4">
        <!-- arrow icon -->
        <div class="flex items-center">
          <IconArrowBackward class="mt-8 cursor-pointer" @click="navigateBack" />
          <h1 class="mt-8 ml-4 text-label-bold-1">
            {{ $t("company-settings.general") }}
          </h1>
        </div>
        <div class="mt-8"></div>
      </div>
      <hr class="mb-4 ml-[-1rem] mr-[-1rem]" />
      <div class="p-4 bg-1-static">
        <form>
          <!-- Form fields -->
          <SearchInputV2
            v-model="searchTerm"
            :label="$t('company-settings.company-name')"
            name="companyName"
            class="pb-2"
            :placeholder="$t('company-settings.company-name')"
            @input="submitSearchTerm(searchTerm)"
          />
        </form>
        <div class="bg-1-static">
          <ul>
            <div class="mb-3 text-label-bold-3 text-color-label-2">
              {{
                $t(
                  `company-settings.tenantSettingsStore.searchStatus.${tenantSettingsStore.searchStatus}`,
                )
              }}
            </div>
            <li
              v-for="(company, index) in tenantSettingsStore.searchResults"
              :key="index"
              class="flex items-center justify-between py-3 border-t border-solid border-divider"
            >
              <div>
                <div class="text-label-bold-1">
                  {{ truncateString(company.navn, 24) }}
                </div>
                <div class="text-label-bold-2 text-color-label-3">
                  {{
                    truncateString(formatAddress(company.forretningsadresse?.adresse), 24)
                  }}
                </div>
                <div class="text-label-bold-3 text-color-label-4">
                  {{ `Org. nr.: ${company?.organisasjonsnummer}` }}
                </div>
              </div>
              <div>
                <Button type="secondary" @click="selectCompanyClick(company)"
                  >{{ $t("select") }}
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="fixed inset-x-0 bottom-0"></div>
    </div>
    <div v-if="isRetrieving" class="flex items-center justify-center h-full mb-4">
      <clip-loader class="mt-auto mb-auto" :color="'#004DE7'" size="40px"></clip-loader>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTenantStore } from "@/stores/tenantSettingsStore"
import type { BrregCompany } from "@/types"
import { debounce, formatAddress, truncateString } from "@/utils/companyUtils"
import { useForm } from "vee-validate"
import { useRouter } from "vue-router"
import * as Yup from "yup"

const tenantSettingsStore = useTenantStore()
const isRetrieving = ref(false)
const searchTerm = ref("")
const router = useRouter()

const schema = Yup.object().shape({
  companyName: Yup.string(),
})
enum SearchStatus {
  SelectCompany = "SelectCompany",
  Loading = "Loading",
  None = "None",
}
const { errors } = useForm({ validationSchema: schema })

async function selectCompanyClick(company: BrregCompany) {
  if (Object.keys(errors.value).length === 0) {
    tenantSettingsStore.selectedCompany = company
    tenantSettingsStore.changeCompany(
      company?.navn,
      company.organisasjonsnummer?.toString(),
    )
    if (!tenantSettingsStore.owner) throw Error("tenant owner not defined")
    await tenantSettingsStore.updateTenantOwner(tenantSettingsStore.owner)
    navigateBack()
  }
}

async function navigateBack(): Promise<void> {
  await router.push({ name: "general" })
}

function submitSearchTerm(searchTerm: string) {
  debounce(() => {
    tenantSettingsStore.searchCompany(searchTerm)
  }, 300)
}

onMounted(async () => {
  if (tenantSettingsStore.owner === null) {
    navigateBack()
  }
  tenantSettingsStore.searchStatus = SearchStatus.None
  tenantSettingsStore.searchResults = undefined
})
</script>

<style scoped></style>
