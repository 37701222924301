<template>
  <svg
    class="text-color-link-static"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.70712 10.7071L5.29291 9.29289L12 2.58578L18.7071 9.29289L17.2929 10.7071L13 6.41421V20H11V6.41421L6.70712 10.7071Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>
