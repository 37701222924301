<template>
  <div ref="container" class="pb-[1.4mm]">
    <div class="flex gap-[2.8mm] rounded-[2.8mm] bg-secondary p-[5.6mm] text-caption-2">
      <div class="w-[7mm] shrink-0">{{ number }}</div>
      <div class="grow">
        <div class="flex w-full justify-between gap-[5.6mm]">
          <div>
            <div class="text-label-bold-3">{{ post.name }}</div>
            <div class="whitespace-pre-wrap text-caption-2 text-color-label-2">
              <template v-if="post.description && project.visible_post_description">
                {{ post.description }}
              </template>
            </div>
          </div>
          <div class="shrink-0 text-right">
            <div v-if="project.visible_post_price" class="text-label-bold-3">
              {{ formatCurrency(Number(post.qty) * Number(post.unit_price)) }}
            </div>

            <div v-if="project.visible_post_qty" class="text-caption-2">
              {{ formatNumber(Number(post.qty)) }}
              {{ $t(`units.${post.unit}`) }}
              <template v-if="project.visible_post_price">
                x {{ formatCurrency(post.unit_price) }}
              </template>
            </div>
          </div>
        </div>

        <template v-if="post.pictures.length > 0 && project.visible_post_photos">
          <div></div>
          <div class="mt-[5.6mm] flex flex-wrap gap-[2.6mm]">
            <img
              v-for="(_, imgSrc) in images"
              :key="imgSrc"
              class="rounded-2xl"
              :src="imgSrc"
              @load="loaded(imgSrc)"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Post } from "@/types"
import { formatCurrency, formatNumber } from "@/utils/companyUtils"
import { computed, defineEmits, defineProps, inject, nextTick, onMounted, ref } from "vue"
import { PdfProject } from "../types"

const props = defineProps<{
  post: Post
  number: number
  project: PdfProject
}>()

const assetUrl = inject("assetUrl") as (assetId: string) => string

const emit = defineEmits<{
  ready: [value: number]
}>()
const container = ref<HTMLElement | null>(null)

const images = computed(() => {
  const urls: Record<string, boolean> = {}
  for (const picture of props.post.pictures) {
    const src = getFullImageUrl(picture.directus_files_id.id)
    urls[src] = true
  }

  return urls
})

onMounted(() => nextTick(checkItemReady))

function loaded(src: string) {
  images.value[src] = false
  checkItemReady()
}

function getFullImageUrl(pictureId: string) {
  let url = assetUrl(pictureId)
  const key = props.project.post_image_size
  if (key !== "lg") {
    url += `&key=${key}`
  }
  return url
}

function checkItemReady() {
  if (!container.value) return
  if (props.project.visible_post_photos && Object.values(images.value).some(Boolean))
    return
  emit("ready", container.value.clientHeight)
}
</script>
