import {
  ICustomer,
  IEmployee,
  IProject,
  ITenant,
  IUser,
  IPhase,
  IPost,
} from "@/types/DateTypes"
import { FirebaseFirestore } from "@capacitor-firebase/firestore"
import { FirebaseAuthentication } from "@capacitor-firebase/authentication"
import { QuoteStatuses } from "@/types"
import { useGlobalStore } from "@/stores/globalStore"

export function loadTenant(tenantId: string) {
  return FirebaseFirestore.addDocumentSnapshotListener(
    {
      reference: `tenants/${tenantId}`,
    },
    (event, error) => {
      if (error) {
        console.warn("Failed fetching tenant", tenantId)
        console.error(error)
      } else {
      }
    },
  )
}

export function loadProjects(tenantId: string, statusFilter: QuoteStatuses[]) {
  const dataStore = useDataStore()

  return FirebaseFirestore.addCollectionSnapshotListener<IProject>(
    {
      reference: `tenants/${tenantId}/projects`,
      compositeFilter: {
        type: "and",
        queryConstraints: [
          {
            type: "where",
            fieldPath: "quote_status",
            opStr: "in",
            value: statusFilter,
          },
        ],
      },
      queryConstraints: [
        {
          type: "orderBy",
          fieldPath: "date_updated",
          directionStr: "desc",
        },
        {
          type: "limit",
          limit: 100,
        },
      ],
    },
    (event, error) => {
      if (error) {
        console.warn("Failed fetching projects", tenantId)
        console.error(error)
      } else if (event) {
        // Use batch updates instead of individual updates
        const projectUpdates = event.snapshots
          .filter(project => project.data)
          .map(project => project.data!)
        dataStore.setProjects(projectUpdates)
      }
    },
  )
}

export function loadCustomers(tenantId: string) {
  const dataStore = useDataStore()
  return FirebaseFirestore.addCollectionSnapshotListener<ICustomer>(
    {
      reference: `tenants/${tenantId}/customers`,
      queryConstraints: [
        {
          type: "orderBy",
          fieldPath: "date_updated",
          directionStr: "desc",
        },
        {
          type: "limit",
          limit: 500,
        },
      ],
    },
    (event, error) => {
      if (error) {
        console.warn("Failed fetching customers", tenantId)
        console.error(error)
      } else if (event) {
        // Use batch updates instead of individual updates
        const customerUpdates = event.snapshots
          .filter(customer => customer.data)
          .map(customer => customer.data!)
        dataStore.setCustomers(customerUpdates)
      }
    },
  )
}

export function loadEmployees(tenantId: string) {
  const dataStore = useDataStore()
  return FirebaseFirestore.addCollectionSnapshotListener<IEmployee>(
    {
      reference: `tenants/${tenantId}/employees`,
      compositeFilter: {
        type: "and",
        queryConstraints: [
          {
            type: "where",
            fieldPath: "role",
            opStr: "in",
            value: ["admin", "employee"],
          },
        ],
      },
      queryConstraints: [
        {
          type: "limit",
          limit: 50,
        },
      ],
    },
    (event, error) => {
      if (error) {
        console.warn("Failed fetching employees", tenantId)
        console.error(error)
      } else if (event) {
        // Use batch updates instead of individual updates
        const employeeUpdates = event.snapshots
          .filter(employee => employee.data)
          .map(employee => employee.data!)
        dataStore.setEmployees(employeeUpdates)
      }
    },
  )
}

export function loadTenants() {
  const dataStore = useDataStore()
  const globalStore = useGlobalStore()

  const firebaseToken = globalStore.firebaseToken
    ? JSON.parse(atob(globalStore.firebaseToken.split(".")[1]))
    : null

  if (!firebaseToken) {
    console.warn("Firebase token not available")
    return null
  }

  const tenantIds = Object.keys(firebaseToken.claims)

  return FirebaseFirestore.addCollectionSnapshotListener<ITenant>(
    {
      reference: "tenants",
      compositeFilter: {
        type: "or",
        queryConstraints: tenantIds.map(tenantId => ({
          type: "where",
          fieldPath: "__name__",
          opStr: "==",
          value: tenantId,
        })),
      },
      queryConstraints: [
        {
          type: "orderBy",
          fieldPath: "date_updated",
          directionStr: "desc",
        },
        {
          type: "limit",
          limit: 100,
        },
      ],
    },
    (event, error) => {
      if (error) {
        console.warn("Failed fetching tenants")
        console.error(error)
      } else if (event) {
        const tenantUpdates = event.snapshots
          .filter(tenant => tenant.data)
          .map(tenant => tenant.data!)
        dataStore.setTenants(tenantUpdates)
      }
    },
  )
}

export async function loadUser() {
  const dataStore = useDataStore()

  try {
    const { user } = await FirebaseAuthentication.getCurrentUser()

    if (!user) {
      console.warn("No authenticated user found")
      return null
    }

    const userId = user.uid

    return FirebaseFirestore.addDocumentSnapshotListener<IUser>(
      {
        reference: `users/${userId}`,
      },
      (event, error) => {
        if (error) {
          console.warn("Failed fetching user", userId)
          console.error(error)
        } else if (event && event.snapshot && event.snapshot.data) {
          const userData = event.snapshot.data
          dataStore.setUser(userData)
        }
      },
    )
  } catch (error) {
    console.error("Error getting current user:", error)
    return null
  }
}

// Add new functions for loading phases and posts
export function loadPhases(tenantId: string, projectId: string) {
  const dataStore = useDataStore()

  return FirebaseFirestore.addCollectionSnapshotListener<IPhase>(
    {
      reference: `tenants/${tenantId}/projects/${projectId}/phases`,
      queryConstraints: [
        {
          type: "orderBy",
          fieldPath: "sort",
          directionStr: "asc",
        },
      ],
    },
    (event, error) => {
      if (error) {
        console.warn("Failed fetching phases", projectId)
        console.error(error)
      } else if (event) {
        const phaseUpdates = event.snapshots
          .filter(phase => phase.data)
          .map(phase => phase.data!)
        dataStore.setPhases(phaseUpdates)
      }
    },
  )
}

export function loadPosts(tenantId: string, projectId: string) {
  const dataStore = useDataStore()

  return FirebaseFirestore.addCollectionSnapshotListener<IPost>(
    {
      reference: `tenants/${tenantId}/projects/${projectId}/posts`,
      queryConstraints: [
        {
          type: "orderBy",
          fieldPath: "sort",
          directionStr: "asc",
        },
      ],
    },
    (event, error) => {
      if (error) {
        console.warn("Failed fetching posts", projectId)
        console.error(error)
      } else if (event) {
        const postUpdates = event.snapshots
          .filter(post => post.data)
          .map(post => post.data!)
        dataStore.setPosts(postUpdates)
      }
    },
  )
}

// Add new function for loading post templates
export function loadPostTemplates(tenantId: string) {
  const dataStore = useDataStore()

  return FirebaseFirestore.addCollectionSnapshotListener<IPost>(
    {
      reference: `tenants/${tenantId}/posts`,
      queryConstraints: [
        {
          type: "orderBy",
          fieldPath: "date_created",
          directionStr: "desc",
        },
      ],
    },
    (event, error) => {
      if (error) {
        console.warn("Failed fetching post templates", tenantId)
        console.error(error)
      } else if (event) {
        const templateUpdates = event.snapshots
          .filter(template => template.data)
          .map(template => template.data!)
        dataStore.setPostTemplates(templateUpdates)
      }
    },
  )
}
