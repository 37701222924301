<template>
  <div class="flex items-center">
    <div class="grow">
      <TopNav
        :type="postNavType"
        :title="context === 'stand-alone-view' ? title : ''"
        :saving="postStore.inProgress"
        :devider="context === 'stand-alone-view'"
        @exit="handleExit"
        @back="postStore.goBack"
        @save="postStore.goBack"
      >
        <template #options>
          <PostOptions />
        </template>
      </TopNav>
    </div>
    <IconCollapse v-if="context === 'quote-view'" @click="activePostId = undefined" />
  </div>
</template>

<script setup lang="ts">
import { useDeleteStore } from "@/stores/useDeleteStore"

const { t } = useI18n()

provide<boolean>("editing", true)
const context = inject("context") as Ref<"quote-view" | "stand-alone-view">
const activePostId = inject<Ref<string | undefined>>("activePostId")
const postStore = usePostStore()
const deleteStore = useDeleteStore()

const postNavType = ref<"new" | "edit" | "none">("none")
if (postStore.status === "new") {
  postNavType.value = "new"
} else if (postStore.status === "edit") {
  postNavType.value = "edit"
}
if (context.value === "quote-view") {
  postNavType.value = "none"
}

const title = computed(() => {
  if (postStore.post.is_template) {
    if (postStore.status === "new") {
      return t("templates.new_template")
    } else {
      return t("edit_as_template")
    }
  } else {
    if (postStore.status === "new") {
      return t("quote.new_post")
    } else {
      return t("edit_post")
    }
  }
})

const handleExit = () => {
  deleteStore.setItemToDelete(postStore.post.id, "posts")
}

watch(
  () => deleteStore.itemDeleted,
  async newValue => {
    if (newValue) {
      postStore.post.status = "archived"
      await postStore.goBack()
      deleteStore.itemDeleted = false
    }
  },
)
</script>
