import { greaterThan as originalGreaterThan } from "@/composables/validation"
import { i18n } from "@/localization/i18n"
import * as validators from "@vuelidate/validators"

const { t } = i18n.global
const { createI18nMessage } = validators

const withI18nMessage = createI18nMessage({ t: t.bind(i18n) })

export const helper = validators.helpers

export const required = withI18nMessage(validators.required)
export const requiredWithInputName = (inputNamePath: string, vars: unknown[] = []) =>
  helper.withMessage(
    () => t("validations.required", { property: t(inputNamePath, vars) }),
    required,
  )
export const email = withI18nMessage(validators.email)
export const emailWithInputName = (inputNamePath: string, vars: unknown[] = []) =>
  helper.withMessage(
    () => t("validations.email", { property: t(inputNamePath, vars) }),
    email,
  )
export const decimal = withI18nMessage(validators.decimal)
export const decimalWithInputName = (inputNamePath: string, vars: unknown[] = []) =>
  helper.withMessage(
    () => t("validations.decimal", { property: t(inputNamePath, vars) }),
    decimal,
  )
export const minLength = withI18nMessage(validators.minLength, {
  withArguments: true,
})
export const minValue = withI18nMessage(validators.minValue, {
  withArguments: true,
})
export const greaterThan = withI18nMessage(originalGreaterThan, {
  withArguments: true,
})
export const greaterThanWithInputName = (inputNamePath: string, value: number) =>
  helper.withMessage(
    () => t("validations.greaterThan", { property: t(inputNamePath), min: value }),
    greaterThan(value),
  )
