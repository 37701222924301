<template>
  <PageLayout>
    <template #header>
      <TopNav
        :title="$t('company-settings.employees')"
        :show-additiol-options="false"
        type="edit"
        :saving="saving"
        @back="$router.back()"
      >
        <template #options>
          <Button
            v-if="!mobile"
            icon="plus"
            size="medium"
            @click="employeeController.setCreating"
            >{{ $t("employees.new_employee") }}</Button
          >
        </template>
      </TopNav>
    </template>
    <template #default>
      <p class="mb-4 text-label-3 text-color-label-3">
        {{ $t("employees.explanation") }}
      </p>

      <div class="space-y-2">
        <div v-for="e in dataStore.employeesById" :key="e.id">
          <EmployeeSummary
            :employee="e"
            :mode="'edit'"
            @click="employeeController.setEditing(e)"
          />
        </div>
      </div>

      <EmployeePlaceholder v-if="Object.keys(dataStore.employeesById).length === 0" />
    </template>
    <template #footer-mobile>
      <Button
        icon="plus"
        class="w-full"
        size="large"
        @click="employeeController.setCreating"
        >{{ $t("employees.new_employee") }}</Button
      >
    </template>
  </PageLayout>
  <Employee />
</template>

<script setup lang="ts">
const dataStore = useDataStore()
const employeeController = useEmployeeController()
const saving = ref(false)

const mobile = inject("isPhone") as Ref<boolean>

// Define and provide the employee status badge mapping directly
const employeeStatusBadgeMap: Record<string, BadgeVariant> = {
  active: "success",
  invited: "warning",
  inactive: "error",
}

provide("employeeStatusBadgeMap", employeeStatusBadgeMap)
</script>
