<template>
  <div v-if="projectStore.project">
    <OptionsButton>
      <div class="p-5 space-y-3">
        <ImportXml v-if="globalStore.isDeveloper" @update="convertToProject" />
        <Button
          v-if="globalStore.isDeveloper"
          type="secondary"
          size="large"
          class="w-full"
          @click="getTenantIdToDuplicateTo"
        >
          <p>Copy to different tenant</p>
        </Button>
        <Button
          v-if="globalStore.isDeveloper"
          type="secondary"
          size="large"
          class="w-full"
          @click="deleteAllPosts"
        >
          <p class="text-color-error">{{ t("quote.delete_all_posts") }}</p>
        </Button>
        <Button type="secondary" size="large" class="w-full" @click="handleDeleteQuote">
          <p class="text-color-error">{{ t("delete_quote") }}</p>
        </Button>
      </div>
    </OptionsButton>
  </div>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { useProjectStore } from "@/stores/projectStore"

const projectStore = useProjectStore()
const postStore = usePostStore()
const globalStore = useGlobalStore()
const deleteStore = useDeleteStore()

const showOptions = ref<boolean>(false)
const router = useRouter()

async function refresh() {
  await router.push({ name: "quotes" })
}

async function convertToProject(data: any) {
  const sbProject = convertSbToProject(data)

  sbProject.phases?.forEach(async phase => {
    phase.posts?.forEach(async post => {
      if (post.name) {
        await postStore.newPost({
          ...post,
          name: post.name,
        })
      }
    })
  })

  showOptions.value = false
}

async function deleteAllPosts() {
  await postStore.deleteAllPosts()
  showOptions.value = false
}

async function getTenantIdToDuplicateTo() {
  const userInput = prompt("Please enter the tenantId to duplicate to:")

  if (userInput === null || !projectStore.project?.id) {
    alert("No input provided.")
    return
  }
  await projectStore.duplicate(projectStore.project.id, userInput)
}

function handleDeleteQuote() {
  if (projectStore.project?.id) {
    deleteStore.setItemToDelete(projectStore.project.id, "projects")
  }
}

watch(
  () => deleteStore.itemDeleted,
  async newValue => {
    if (newValue) {
      await refresh()
      deleteStore.itemDeleted = false
    }
  },
)
</script>
