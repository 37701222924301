<template>
  <div>
    <label v-if="label != ''" class="block mb-1 text-label-2 text-color-label-1">
      {{ label }}
    </label>
    <div
      class="hide-scrollbar max-h-[40dvh] overflow-y-auto rounded-xl"
      :class="{ 'bg-1-static': invertBackground, 'bg-2-static': !invertBackground }"
    >
      <div
        v-for="(option, index) in options"
        :key="option.id"
        @click="selectOption(option.id, true)"
      >
        <div
          v-if="type !== 'multiple'"
          class="flex items-center justify-between py-3 rounded-xl"
          :class="{
            'hover:bg-1-hover': invertBackground,
            'hover:bg-2-hover': !invertBackground,
          }"
        >
          <div class="ml-4">
            <div class="text-label-bold-1 text-color-label-1">
              {{ option.label }}
            </div>
            <div class="text-label-bold-2 text-color-label-2">
              {{ option.subLabel }}
            </div>
          </div>
          <div class="mr-4"></div>
          <div v-if="type === 'single'" class="mr-4">
            <IconCheckMark v-if="option.selected" class="text-color-link-static" />
          </div>
          <div v-if="type === 'navigation'" class="mr-4">
            <IconArrowForward />
          </div>
        </div>
        <CheckBox
          v-else
          :model-value="option.selected"
          :label="option.label"
          size="small"
          @update:model-value="value => selectOption(option.id, value)"
          @click.stop
        />
        <hr v-if="index !== options.length - 1" class="border-divider" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Option {
  label: string
  subLabel?: string
  id: string
  selected: boolean
}

interface Props {
  options: Option[]
  label?: string
  type?: "single" | "multiple" | "navigation"
  invertBackground?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  type: "single",
  invertBackground: false,
  label: "",
})

const emit = defineEmits<{
  "update:options": [options: Option[]]
  selectedItem: [option: Option]
  selectedId: [id: string]
}>()

const selectOption = (id: string, value: boolean) => {
  const newOptions =
    props.type === "multiple" ? handleMultipleSelect(id, value) : handleSingleSelect(id)

  emit("update:options", newOptions)

  const selectedOption = newOptions.find(option => option.id === id)
  if (selectedOption) {
    emit("selectedItem", selectedOption)
    emit("selectedId", id)
  }
}

function handleSingleSelect(id: string) {
  return props.options.map(option => ({
    ...option,
    selected: option.id === id,
  }))
}

function handleMultipleSelect(id: string, value: boolean) {
  return props.options.map(option => ({
    ...option,
    selected: option.id === id ? value : option.selected,
  }))
}
</script>
