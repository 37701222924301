<template>
  <svg
    width="248"
    height="286"
    viewBox="0 0 265 306"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_ddd_114_4616)">
      <rect
        x="19.0957"
        y="43.6626"
        width="188.096"
        height="239.396"
        rx="9.60073"
        transform="rotate(-10 19.0957 43.6626)"
        fill="white"
      />
      <circle
        cx="48.8059"
        cy="64.905"
        r="9.8109"
        transform="rotate(-10 48.8059 64.905)"
        fill="#0055FF"
      />
      <rect
        x="66.0674"
        y="58.7154"
        width="61.4508"
        height="6.19669"
        rx="3.09834"
        transform="rotate(-10 66.0674 58.7154)"
        fill="#000D1A"
        fill-opacity="0.08"
      />
      <rect
        x="46.6475"
        y="106.186"
        width="144.589"
        height="5.16392"
        rx="2.58196"
        transform="rotate(-10 46.6475 106.186)"
        fill="#000D1A"
        fill-opacity="0.08"
      />
      <rect
        x="52.7344"
        y="140.769"
        width="144.61"
        height="5.16465"
        rx="2.58232"
        transform="rotate(-10 52.7344 140.769)"
        fill="#000D1A"
        fill-opacity="0.08"
      />
      <rect
        x="55.8994"
        y="158.718"
        width="81.5999"
        height="5.16465"
        rx="2.58232"
        transform="rotate(-10 55.8994 158.718)"
        fill="#000D1A"
        fill-opacity="0.08"
      />
      <rect
        x="72.873"
        y="254.982"
        width="38.6318"
        height="5.16465"
        rx="2.58232"
        transform="rotate(-10 72.873 254.982)"
        fill="#0055FF"
      />
      <rect
        x="118.312"
        y="246.97"
        width="23.594"
        height="5.16465"
        rx="2.58232"
        transform="rotate(-10 118.312 246.97)"
        fill="#0055FF"
      />
      <rect
        x="49.6953"
        y="123.476"
        width="115.155"
        height="5.16392"
        rx="2.58196"
        transform="rotate(-10 49.6953 123.476)"
        fill="#000D1A"
        fill-opacity="0.08"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_114_4616"
        x="-0.904297"
        y="-1"
        width="266.81"
        height="308.422"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_114_4616"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_114_4616"
          result="effect2_dropShadow_114_4616"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="1"
          operator="dilate"
          in="SourceAlpha"
          result="effect3_dropShadow_114_4616"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_114_4616"
          result="effect3_dropShadow_114_4616"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow_114_4616"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>
