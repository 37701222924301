<template>
  <p class="mb-2 text-label-bold-1">{{ $t("onboarding.logo_2") }}</p>
  <div
    class="mb-6 flex items-center rounded-xl bg-2-static p-4"
    @click="handleFileSelection"
  >
    <div v-if="fileStore.fileName === ''" class="grow">
      <p class="text-label-bold-1 text-color-link-static">
        {{ $t("onboarding.logo_2") }}
      </p>
      <p class="text-label-2 text-color-label-3">
        {{ $t("onboarding.logo_3") }}
      </p>
    </div>
    <div v-if="fileStore.fileName !== ''" class="grow">
      <p class="text-label-2 text-color-label-1">
        {{ fileStore.fileName }}
      </p>
    </div>
    <div>
      <p v-if="onboardingStore.loading" class="text-label-2 text-color-link-static">
        {{ $t("onboarding.logo_3") }}
      </p>
      <IconArrowUp />
    </div>
  </div>
  <!-- <input
    ref="fileInput"
    type="file"
    class="hidden"
    accept="image/png,image/jpeg,image/gif"
    @change="handleFileSelection"
  /> -->
</template>

<script setup lang="ts">
import { useFileStore } from "@/stores/fileStore"
import { useOnboardingStore } from "@/stores/onboardingStore"
import { useTenantStore } from "@/stores/tenantSettingsStore"
import { getColorPalette } from "@/utils/companyUtils"

const tenantSettingsStore = useTenantStore()
const onboardingStore = useOnboardingStore()
const fileStore = useFileStore()

async function handleFileSelection(event: Event) {
  const result = await fileStore.retrieveImage()
  if (result) {
    pickColorsFromLogo()
  }
}

const pickColorsFromLogo = (event?: Event) => {
  const img = new Image()
  img.src = fileStore.fileLocalUrl
  tenantSettingsStore.logoUrl = img.src
  img.onload = () => {
    tenantSettingsStore.colorPalette = getColorPalette(img)
  }
}

onMounted(async () => {
  try {
    tenantSettingsStore.logoUrl = await tenantSettingsStore.getCompanyLogoUrl
    const img = new Image()
    img.src = tenantSettingsStore.logoUrl
    img.crossOrigin = "Anonymous"
    img.onload = () => {
      tenantSettingsStore.colorPalette = getColorPalette(img)
    }
  } catch (error) {
    console.error(error)
  }
})
</script>
