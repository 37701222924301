<template>
  <div class="flex my-4">
    <UploadFile />
    <PostPhotos
      :file-ids="postStore.post?.pictures.map(item => item.directus_files_id.id)"
      :wrap-photos="false"
      class="overflow-x-auto"
    />
  </div>
</template>

<script setup lang="ts">
import { usePostStore } from "@/stores/postStore"

const postStore = usePostStore()
</script>
