<template>
  <div class="relative">
    <div
      ref="colorPickerContainer"
      class="absolute left-0 mb-2 bottom-full"
      v-show="colorPickerVisible"
    >
      <color-picker
        :rgb="colorValue"
        :history="true"
        @input="handleColorChange(hueToRgb($event))"
        @select="handleHueSelect"
      />
    </div>
    <p class="mb-2 text-label-bold-1">
      {{ $t("onboarding.logo_5") }}
    </p>
    <div class="flex items-center space-x-4">
      <div
        class="relative w-10 h-10 rounded-full cursor-pointer bg-2-static"
        :style="{ backgroundColor: tenantStore.selectedColor }"
      >
        <div
          v-show="!colorPickerVisible"
          class="flex items-center justify-center w-full h-full rounded-full"
          @click="openColorPicker"
        >
          <IconColorPicker />
        </div>
      </div>
      <div
        v-for="(color, index) in tenantStore.colorPalette"
        :key="index"
        class="w-10 h-10 rounded-full"
        :style="
          color === tenantStore.selectedColor
            ? {
                backgroundColor: 'white',
                borderColor: color,
                borderWidth: '8px',
              }
            : {
                backgroundColor: color,
                borderColor: 'transparent',
                borderWidth: '2px',
              }
        "
        @click="colorPaletteClick(color)"
      ></div>
    </div>
  </div>
  <MessageAlert v-if="tenantStore.error" class="w-full my-2" type="error">
    {{ $t("onboarding.logo_6") }}
  </MessageAlert>
</template>

<script setup lang="ts">
import { useGlobalStore } from "@/stores/globalStore"
import { useTenantStore } from "@/stores/tenantSettingsStore"
import ColorPicker from "@radial-color-picker/vue-color-picker"
import "@radial-color-picker/vue-color-picker/dist/vue-color-picker.min.css"
import { onClickOutside } from "@vueuse/core"
import { ref, onMounted } from "vue"

const tenantStore = useTenantStore()
const globalStore = useGlobalStore()
const colorPickerContainer = ref<HTMLDivElement>()
const colorPickerVisible = ref(false)

// Initialize color value in RGB format
const colorValue = ref({
  r: 0,
  g: 0,
  b: 0,
})

onMounted(() => {
  // Convert initial hex color to RGB
  const initialColor = globalStore.tenant.invoice_color
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(initialColor)
  if (result) {
    colorValue.value = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
  }
})

tenantStore.selectedColor = globalStore.tenant.invoice_color

function openColorPicker(): void {
  colorPickerVisible.value = true
}

onClickOutside(colorPickerContainer, event => {
  // Prevent closing if clicking the color picker button
  const target = event.target as HTMLElement
  if (target.closest(".color-picker-button")) {
    return
  }
  colorPickerVisible.value = false
})

function colorPaletteClick(color: string): void {
  tenantStore.selectedColor = color
}

// Handle color selection
function handleColorChange(color: { r: number; g: number; b: number }): void {
  const rgbToHex = (r: number, g: number, b: number): string => {
    // Ensure values are within valid range (0-255)
    const clamp = (val: number) => Math.min(255, Math.max(0, Math.round(val)))
    const toHex = (val: number) => clamp(val).toString(16).padStart(2, "0")

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`
  }

  const hexColor = rgbToHex(color.r, color.g, color.b)
  tenantStore.selectedColor = hexColor
  colorValue.value = color
}

function handleHueSelect(hue: number): void {
  handleColorChange(hueToRgb(hue))
  colorPickerVisible.value = false
}

function hueToRgb(hue: number) {
  // Normalize hue to 0-360 range
  hue = ((hue % 360) + 360) % 360

  // Using HSL with 100% saturation and 50% lightness
  const s = 1
  const l = 0.5

  const c = (1 - Math.abs(2 * l - 1)) * s
  const x = c * (1 - Math.abs(((hue / 60) % 2) - 1))
  const m = l - c / 2

  let r = 0,
    g = 0,
    b = 0

  if (hue >= 0 && hue < 60) {
    ;[r, g, b] = [c, x, 0]
  } else if (hue < 120) {
    ;[r, g, b] = [x, c, 0]
  } else if (hue < 180) {
    ;[r, g, b] = [0, c, x]
  } else if (hue < 240) {
    ;[r, g, b] = [0, x, c]
  } else if (hue < 300) {
    ;[r, g, b] = [x, 0, c]
  } else {
    ;[r, g, b] = [c, 0, x]
  }

  return {
    r: Math.round((r + m) * 255),
    g: Math.round((g + m) * 255),
    b: Math.round((b + m) * 255),
  }
}
</script>

<style>
.vue-color-picker {
  --rcp-diameter: 200px;
  --rcp-knob-size: 16px;
  --rcp-bar-size: 16px;
}
</style>
