import { inject, type Ref } from "vue"
import { CountrySetting } from "@/config/countries"
import ColorThief from "colorthief"
import currency from "currency.js"

export const truncateString = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str
  } else {
    return `${str.slice(0, maxLength)}...`
  }
}

export function formatAddress(addressArray: string[]): string {
  if (!addressArray) {
    return ""
  }
  return addressArray.join(", ")
}

let timer: NodeJS.Timeout | null = null

export function debounce(fn: () => void, delay: number) {
  if (timer) {
    clearTimeout(timer)
  }
  timer = setTimeout(fn, delay)
}

export const rgbToHex = (r: number, g: number, b: number): string => {
  const componentToHex = (c: number): string => {
    const hex = c.toString(16)
    return hex.length === 1 ? "0" + hex : hex
  }

  return "#" + [r, g, b].map(componentToHex).join("")
}

export function getColorPalette(img: HTMLImageElement, count = 4): string[] {
  const colorThief = new ColorThief()
  const palette = colorThief.getPalette(img)

  if (palette) {
    const rgbToHex = (r: number, g: number, b: number): string => {
      const componentToHex = (c: number): string => {
        const hex = c.toString(16)
        return hex.length === 1 ? "0" + hex : hex
      }

      return "#" + [r, g, b].map(componentToHex).join("")
    }

    const hexPalette = palette.map(color => rgbToHex(color[0], color[1], color[2]))
    return hexPalette.slice(0, count)
  }

  return []
}

export function formatCurrency(amount: number | string): string {
  if (typeof amount === "string") amount = parseFloat(amount)
  const countryInfo = inject("countrySetting") as Ref<CountrySetting | undefined | null>
  if (countryInfo.value) {
    return currency(amount, {
      symbol: countryInfo.value.localCurrency,
      pattern: "! #",
      precision: 2,
      decimal: ",",
      separator: " ",
    }).format()
  } else {
    return currency(amount, {
      symbol: "",
      pattern: "! #",
      precision: 2,
      decimal: ",",
      separator: " ",
    }).format()
  }
}

export function formatNumber(num: number): string {
  return Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(num)
}
