<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.1 12.9V20H12.9V12.9H20V11.1H12.9V4H11.1V11.1H4V12.9H11.1Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>
