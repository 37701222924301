<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    class="w-6 text-color-link-static"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.7071 19.2929L15.2929 20.7071L12 17.4142L8.70711 20.7071L7.29289 19.2929L12 14.5858L16.7071 19.2929Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.7071 4.70711L15.2929 3.29289L12 6.58579L8.70711 3.29289L7.29289 4.70711L12 9.41421L16.7071 4.70711Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 13H4V11H20V13Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>
