import { Schema } from "@/types"
import { defineStore } from "pinia"
import { ref } from "vue"

export const useDeleteStore = defineStore("delete", () => {
  const itemToDelete = ref<{
    id: string
    collection: keyof Schema
  } | null>(null)
  const message = ref<string | undefined>(undefined)
  const confirmBeforeDelete = ref<boolean>(true)
  const itemDeleted = ref<boolean>(false)

  function setItemToDelete(
    id: string,
    collection: keyof Schema,
    confirm: boolean = true,
    deleteMessage?: string,
  ) {
    itemToDelete.value = { id, collection }
    confirmBeforeDelete.value = confirm
    message.value = deleteMessage
  }

  function clearItemToDelete() {
    itemToDelete.value = null
  }

  return {
    itemToDelete,
    setItemToDelete,
    clearItemToDelete,
    itemDeleted,
    message,
    confirmBeforeDelete,
  }
})
