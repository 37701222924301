<template>
  <h1 class="mb-4 text-xl font-bold">{{ t("auth.reset_password") }}</h1>
  <div v-if="!globalStore.isLoggedIn">
    <p
      class="y-4 mb-4 w-full border-t-4 border-green-500 bg-green-100 p-4 text-left text-sm"
    >
      {{ t("auth.forgot_password_message") }}
    </p>

    <Button class="mb-4 w-full" @click="mobile">
      <div class="flex items-center justify-center">
        <span>
          <DevicePhoneMobileIcon class="mr-5 h-6 w-6" />
        </span>

        <span>{{ t("auth.mobile") }}</span>
      </div>
    </Button>
  </div>

  <form v-if="globalStore.isLoggedIn" class="w-full" @submit.prevent="submitForm">
    <TextFieldVal
      name="newPassword"
      :placeholder="$t('auth.new_password')"
      type="password"
      text-size="normal"
      :bold="false"
      class="pb-4"
    >
    </TextFieldVal>
    <div class="grow"></div>
    <Button class="mb-2 w-full" :loading="globalStore.loading" submit color="blue">
      {{ $t("auth.new_password_button") }}
    </Button>
  </form>
</template>

<script setup lang="ts">
import { t } from "@/localization/i18n"
import { useGlobalStore } from "@/stores/globalStore"
import { DevicePhoneMobileIcon } from "@heroicons/vue/24/outline"
import { useForm } from "vee-validate"
import * as Yup from "yup"

const globalStore = useGlobalStore()

const router = useRouter()
async function mobile() {
  globalStore.passwordResetStatus = "started"
  await router.push("/auth/login-mobile")
}

const schema = Yup.object().shape({
  newPassword: Yup.string().required(t("validation.required")),
})

const { handleSubmit } = useForm<Yup.InferType<typeof schema>>({
  validationSchema: schema,
})
const submitForm = handleSubmit(async values => {
  await globalStore.resetPassword(values.newPassword)
  await router.push("/offers")
})
</script>
@/localization/i18n
