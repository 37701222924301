<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    class="w-6 text-color-link-static"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 13.2728L17.0205 18.2933L18.2933 17.0205L13.2728 12L18.2933 6.97954L17.0205 5.70675L12 10.7272L6.97954 5.70675L5.70675 6.97954L10.7272 12L5.70675 17.0205L6.97955 18.2933L12 13.2728Z"
    />
  </svg>
</template>

<script setup lang="ts"></script>
