<template>
  <Card v-touch:hold="pressHandler" :clickable="!postIsActive" class="my-3">
    <CardContent>
      <div class="flex">
        <div v-if="activePostId !== id">
          <div
            class="px-2 rounded-lg hover:bg-2-static"
            @click="postStore.updateSortingOnPost(id, 'up')"
          >
            <IconMoveUp />
          </div>
          <div
            class="px-2 rounded-lg hover:bg-2-static"
            @click="postStore.updateSortingOnPost(id, 'down')"
          >
            <IconMoveDown />
          </div>
        </div>
        <div v-if="props.id !== activePostId" class="grow" @click="gotoPost">
          <div class="flex items-center">
            <p class="mb-1 mr-2 whitespace-pre-line grow text-label-bold-1">{{ name }}</p>
            <IconChevronDown class="flex-shrink-0 w-4" v-if="!mobile" />
          </div>

          <div class="flex flex-wrap justify-between gap-4">
            <p class="text-label-2 text-color-label-2/70">
              {{ formatNumber(Number(qty)) }} {{ $t(`units.${unit}`) }} x
              {{ formatCurrency(unitPrice) }}
            </p>
            <p class="text-label-1 text-color-label-1">
              {{ formatCurrency(Number(qty) * Number(unitPrice)) }}
            </p>
          </div>
        </div>
      </div>
      <Post v-if="postIsActive" :id="id" />
    </CardContent>
  </Card>
</template>

<script setup lang="ts">
const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  isTemplate: {
    type: Boolean,
    default: false,
  },
  qty: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  unit: {
    type: String,
    required: true,
  },
  unitPrice: {
    type: String,
    required: true,
  },
})

const postIsActive = computed(() => props.id === activePostId?.value)

const context = ref<"quote-view" | "stand-alone-view">("quote-view")
provide("context", context)
const postStore = usePostStore()
const projectStore = useProjectStore()
const router = useRouter()
const emit = defineEmits(["showPostOptions"])

const activePostId = inject<Ref<string | undefined>>("activePostId")
const showOptions = inject("showOptions") as Ref<boolean>
async function pressHandler() {
  await postStore.loadPost(props.id)
  showOptions.value = true
}

const gotoPost = async () => {
  if (activePostId && !mobile.value) {
    activePostId.value = props.id
  }
  if (!mobile.value || props.isTemplate) return

  const post = postStore.posts.find(post => post.id === props.id)
  postStore.post = post
  postStore.status = "edit"
  await router.push({
    name: "post",
    params: { quoteId: projectStore.project.id, postId: props.id },
  })
}

const mobile = inject("isPhone") as Ref<boolean>
</script>
