<template>
  <div class="hide-scrollbar flex max-h-[75dvh] flex-col overflow-y-auto rounded-2xl">
    <div v-if="projectStore.project" class="mb-4 space-y-3 rounded-2xl bg-2-static">
      <QuoteSettingSummary
        :title="$t('quote.settings.quote_info')"
        :sub-title="$t('quote.quote_number') + ' ' + projectStore.project.quote_number"
      >
        <QuoteSettingsInfo />
      </QuoteSettingSummary>

      <QuoteSettingSummary
        :title="$t('quote.settings.payment_plan')"
        :sub-title="$t('quote.settings.payment_plan_description')"
      >
        <QuoteSettingsPaymentPlan />
      </QuoteSettingSummary>

      <QuoteSettingSummary :title="$t('notes')" :sub-title="projectStore.project.note">
        <QuoteSettingsNotes />
      </QuoteSettingSummary>

      <QuoteSettingSummary
        :title="$t('quote.settings.terms')"
        :sub-title="projectStore.project.terms"
      >
        <QuoteSettingsTerms />
      </QuoteSettingSummary>

      <QuoteSettingSummary
        :title="$t('quote.settings.discount')"
        :sub-title="formatNumber(percentageDiscount) + '%'"
        class=""
      >
        <QuoteSettingsDiscount />
      </QuoteSettingSummary>
    </div>

    <QuoteTotals />
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from "@/stores/globalStore"
import { useProjectStore } from "@/stores/projectStore"
import { useQuoteEmailStore } from "@/stores/quoteEmailStore"
import { formatNumber } from "@/utils/companyUtils"

const route = useRoute()
const projectStore = useProjectStore()
const globalStore = useGlobalStore()
const quoteEmailStore = useQuoteEmailStore()

const emit = defineEmits<{ valid: [val: boolean] }>()

watchEffect(() => {
  if (projectStore.project && !projectStore.project.contact_person) {
    emit("valid", true)
  } else {
    emit("valid", false)
  }
})

const quoteId = route.params.quoteId as string

if (!projectStore.project || projectStore.project.id !== quoteId)
  await projectStore.setProject(quoteId)

if (quoteEmailStore.projectId !== projectStore.project.id)
  quoteEmailStore.initState(globalStore.tenant, projectStore.project)

const percentageDiscount = computed(() => {
  if (!projectStore.project) return 0
  const discount = Number(projectStore.project.discount_amount)
  if (isNaN(discount) || !discount) return 0

  return (discount / projectStore.grandTotal) * 100
})
</script>
