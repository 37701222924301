<template>
  <svg
    class="text-color-link-static"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7071 6.70711L9.29289 5.29289L2.58578 12L9.29289 18.7071L10.7071 17.2929L6.41421 13H20V11H6.41421L10.7071 6.70711Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>
