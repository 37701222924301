import { Tenant, User } from "@/types"

export function createFullstorySession(user: User, tenant: Tenant): void {
  // Set FullStory user variables
  if (window.FS && typeof window.FS.identify === "function") {
    if (!tenant.is_production || user.developer) {
      // Stop recording for developer users
      window.FS.shutdown()
    } else {
      // Resume recording for non-developer users
      window.FS.restart()
      window.FS.setUserVars({
        displayName: user.first_name + " " + user.last_name,
        email: user.email,
        // Add your own custom user variables here
        is_developer: user.developer,
        tenant: tenant.name,
        is_production: tenant.is_production,
        subscription_status: tenant.subscription_status,
      })
      window.FS.identify(user.id, {
        displayName: user.first_name + " " + user.last_name,
        email: user.email,
        // Add your own custom user variables here
        is_developer: user.developer,
        tenant: tenant.name,
        is_production: tenant.is_production,
      })
    }
  }
}

export function createFullstoryOnboardingSession(): void {
  // Set FullStory user variables
  if (window.FS && typeof window.FS.identify === "function") {
    window.FS.restart()
    window.FS.setUserVars({
      subscription_status: "onboarding",
    })
  }
}
