<template>
  <Card v-if="quote" class="relative h-48 max-h-48">
    <CardHeader>
      {{ customer ? customer.name : "---" }}
    </CardHeader>

    <CardContent>
      <div class="truncate text-label-2 text-color-label-2">
        {{ quote.name }}
      </div>

      <div
        v-if="quote.subtotal_amount > 0"
        class="font-bold text-caption-1 text-color-label-2"
      >
        {{ formatCurrency(quote.subtotal_amount) }}
        <span class="text-label-3 text-color-label-4">
          {{ $t("quote.total_excl_tax_short") }}
        </span>
      </div>

      <div class="absolute bottom-7 left-3 text-label-3">
        <QuoteStatus
          :current-status="quote.quote_status"
          :show-status-as-label="true"
          @change-status="$emit('change-status', $event)"
        />
      </div>
      <div class="absolute bottom-3 right-3 w-14 text-color-link-static">
        <div class="flex items-center justify-end">
          <a
            v-if="customer && customer.phone_number"
            class="mr-2 text-color-link-static"
            :href="'tel:' + getLocalPhoneNumber(customer.phone_number ?? '')"
            @click.stop
          >
            <PhoneIcon class="w-4 text-color-link-static" />
          </a>

          <IconDuplicate v-if="quote" @click="$emit('duplicate', quote.id)" />
        </div>
      </div>
      <p class="absolute w-6/12 truncate bottom-3 left-3 text-label-3 text-color-label-4">
        {{ date }}
      </p>
    </CardContent>
  </Card>
  <Card v-else class="h-48 max-h-48" />
</template>

<script setup lang="ts">
import { PhoneIcon } from "@heroicons/vue/24/outline"
import { IProject } from "@/types/DateTypes"
import { formatDistanceToNow } from "date-fns"
import { getLocalPhoneNumber } from "@/utils/sanitizeMobileNumber"

const { quote } = defineProps<{
  quote?: IProject
}>()

const dateTimeStamp = quote?.date_updated ?? undefined
let date: string | undefined = undefined
if (dateTimeStamp) {
  date = formatDistanceToNow(new Date(dateTimeStamp), { addSuffix: true })
}

const customer = computed(() => quote && toValue(quote.$customer))
</script>
