<template>
  <svg
    class="text-color-link-static"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 5.5C7.5 3.84315 8.84315 2.5 10.5 2.5H18.5C20.1569 2.5 21.5 3.84315 21.5 5.5V13.5C21.5 15.1569 20.1569 16.5 18.5 16.5H10.5C8.84315 16.5 7.5 15.1569 7.5 13.5V5.5ZM10.5 4.5C9.94772 4.5 9.5 4.94772 9.5 5.5V13.5C9.5 14.0523 9.94772 14.5 10.5 14.5H18.5C19.0523 14.5 19.5 14.0523 19.5 13.5V5.5C19.5 4.94772 19.0523 4.5 18.5 4.5H10.5ZM6 7.5H5.5C3.84315 7.5 2.5 8.84315 2.5 10.5V18.5C2.5 20.1569 3.84315 21.5 5.5 21.5H13.5C15.1569 21.5 16.5 20.1569 16.5 18.5V18H14.5V18.5C14.5 19.0523 14.0523 19.5 13.5 19.5H5.5C4.94772 19.5 4.5 19.0523 4.5 18.5V10.5C4.5 9.94772 4.94772 9.5 5.5 9.5H6V7.5ZM13.6 12.5V10.4H11.5V8.60001H13.6V6.5H15.4V8.60001H17.5V10.4H15.4V12.5H13.6Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>
