import { PhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js"

export default function sanitizePhoneNumber(phoneNumber: string): string {
  let processedNumber = ""
  let parsedPhoneNumber: PhoneNumber | undefined

  try {
    parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber)

    if (parsedPhoneNumber?.isValid()) {
      processedNumber = parsedPhoneNumber.number.toString()
      // remove the + in front of the number
      processedNumber = processedNumber.substring(1)
    }
  } catch (error) {
    throw new Error(`Invalid phone number: ${error}`)
  }

  return processedNumber
}

export function getLocalPhoneNumber(phoneNumber: string): string {
  let parsedPhoneNumber: PhoneNumber | undefined
  let localNumber = ""
  try {
    phoneNumber = "+" + phoneNumber

    parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber)
    if (parsedPhoneNumber?.isValid()) {
      localNumber = parsedPhoneNumber.formatNational()
      return localNumber
    }
  } catch (error) {
    console.error(`Invalid phone number: ${error}`)
  }
  return ""
}
