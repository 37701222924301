<template>
  <div>
    <ClipLoader
      class="flex flex-col items-center justify-center"
      :color="color"
      :size="`${size}px`"
    />
  </div>
</template>
<script setup lang="ts">
interface Props {
  size?: number
  color?: "blue" | "white"
}

const props = withDefaults(defineProps<Props>(), { size: 15, color: "blue" })
const spinner = ref<HTMLDivElement>()
onMounted(() => {
  spinner.value?.style.setProperty("--spinner-size", `${props.size}px`)
  spinner.value?.style.setProperty("--spinner-transform-origin", `${props.size / 2}px`)
  spinner.value?.style.setProperty("--spinner-left", `${props.size / 2.24}px`)
  spinner.value?.style.setProperty("--spinner-bar-width", `${props.size / 10}px`)
  spinner.value?.style.setProperty("--spinner-bar-height", `${props.size / 4}px`)
})
</script>
<style lang="postcss" scoped>
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: var(--spinner-size);
  height: var(--spinner-size);
}
.lds-spinner div {
  transform-origin: var(--spinner-transform-origin) var(--spinner-transform-origin);
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0px;
  left: var(--spinner-left);
  width: var(--spinner-bar-width);
  height: var(--spinner-bar-height);
  border-radius: 30%;
}
.lds-spinner.blue div:after {
  @apply bg-blue-600;
}
.lds-spinner.white div:after {
  @apply bg-white;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.05s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: -0.75s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.45s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.15s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
