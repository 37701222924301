<script setup lang="ts">
import { useRouter } from "vue-router"

const router = useRouter()

const goToLogin = () => {
  router.push({ name: "auth.loginOptions" })
}
</script>

<template>
  <div class="flex h-dvh flex-col items-center justify-center p-4 text-center">
    <h1 class="mb-4 text-heading-1 font-bold text-color-label-1">404 - Page Not Found</h1>
    <p class="mb-8 text-label-1 text-color-label-1">
      Sorry, the page you are looking for does not exist.
    </p>
    <Button @click="goToLogin"> Back to Login </Button>
  </div>
</template>
