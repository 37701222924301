import { withRetry } from "@/api/directus"
import { readMe, readItem, updateItem, updateMe as updateMeDirectus } from "@directus/sdk"
import { Tenant, TenantTypes, User } from "@/types"
import sanitizePhoneNumber from "@/utils/sanitizeMobileNumber"

const tenantFields = [
  "id",
  "name",
  "email",
  "phone_number",
  "street_address",
  "zip_code",
  "city",
  "country_code",
  "company_registration_number",
  "invoice_due_in_days",
  "quote_expire_in_days",
  "visible_vat",
  "visible_post_description",
  "visible_post_price",
  "visible_post_qty",
  "invoice_color",
  "is_production",
  "subscription_status",
  "subscription_id",
  "type",
  "subscription_users",
  "subscription_trial_expire_at",
  "visible_post_qty",
  "subscription_limit_access",
  "terms",
  "note",
  "payment_plan",
  "currency",
  "vat_registered",
  { owner: ["*"] },
  { logo: ["*"] },
] as const

async function updateActiveTenant(tenantId: string): Promise<User> {
  return await withRetry(
    async () => {
      const user = (await client.request(
        updateMeDirectus({
          active_tenant: tenantId,
        }),
      )) as User
      return user
    },
    3,
    true,
  )
}

async function updateUserPassword(newPassword: string): Promise<User> {
  const user = (await client.request(
    updateMeDirectus({
      password: newPassword,
    }),
  )) as unknown as User
  return user
}

async function getMe(): Promise<User> {
  return await withRetry(
    async () => {
      const user = (await client.request(
        readMe({
          fields: [
            "id",
            "avatar",
            "first_name",
            "last_name",
            "email",
            "phone_number",
            "active_tenant",
            "developer",
            "release_notes_last_seen",
            { role: ["id", "name"] },
            { tenants: ["id", "job_title", "status", { tenant: tenantFields }] },
          ],
        }),
      )) as User

      return user
    },
    3,
    false,
  )
}

async function updateMe(fields: {
  first_name?: string
  last_name?: string
  email?: string
  phone_number?: string
  allow_contact_and_marketing?: boolean
  avatar?: string
  release_notes_last_seen?: string
}): Promise<User> {
  return await withRetry(
    async () => {
      const user = (await client.request(updateMeDirectus(fields))) as User
      return user
    },
    3,
    true,
  )
}

async function updateMyTenant(id: string, fields: Partial<Tenant>): Promise<Tenant> {
  return await withRetry(
    async () => {
      await client.request(updateItem("tenants", id, fields))
      return getMyTenant(id)
    },
    3,
    true,
  )
}

async function getMyTenant(tenantId: string): Promise<Tenant> {
  return (await client.request(
    readItem("tenants", tenantId, { fields: tenantFields }),
  )) as Tenant
}

async function sendUserSMSCode(mobileNumber: string): Promise<boolean> {
  const sanitizedNumber = sanitizePhoneNumber(mobileNumber)
  if (!sanitizedNumber) return false
  try {
    const response = await fetch(
      "https://service.tredco.app/flows/trigger/5f6cfa8b-464a-4f46-a32a-548fea1c66a4",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mobile: sanitizedNumber }),
      },
    )
    if (await response.json()) {
      console.log("SMS code has been sent", await response.body)
      return true
    } else {
      console.log("Something went wrong with sending the sms code.")
      return false
    }
  } catch (error) {
    return false
  }
}

async function verifyUserSMSCode(mobileObject: {
  mobile: string
  code: string
  createUser: boolean
  tenantType?: TenantTypes
}): Promise<{ status: boolean; user: User | undefined }> {
  mobileObject.mobile = sanitizePhoneNumber(mobileObject.mobile)
  try {
    const response = await fetch(
      "https://service.tredco.app/flows/trigger/15bd3480-0686-4675-bbc1-b069b0749dc5",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(mobileObject),
      },
    )
    if (response.ok) {
      const responseData = await response.json()
      if (responseData.data.length === 0 || responseData.status !== 200) {
        return { status: false, user: undefined }
      }
      console.log("Logged in via phone", responseData)
      return { status: true, user: responseData.data.data }
    } else {
      console.log("Verification failed, no response data.")
      return { status: false, user: undefined }
    }
  } catch (error) {
    console.error("Verification failed, with the following error", error)
    return { status: false, user: undefined }
  }
}

export const users = {
  getMe,
  getMyTenant,
  updateMe,
  updateMyTenant,
  updateUserPassword,
  updateActiveTenant,
  sendUserSMSCode,
  verifyUserSMSCode,
}
