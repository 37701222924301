import { useGlobalStore } from "@/stores/globalStore"
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteLocationRaw,
  createRouter,
  createWebHistory,
} from "vue-router"

import { routes } from "./routes"
import { useEmployeeController } from "@/stores/employeeController"

export type Breadcrumb = {
  textKey?: string
  text?: string
  to?: RouteLocationRaw
}
export type BreadcrumbsGenerator = (
  route: RouteLocationNormalizedLoaded,
  options?: { [x: string]: unknown },
) => Breadcrumb[]

declare module "vue-router" {
  interface RouteMeta {
    Breadcrumbs?: BreadcrumbsGenerator
    unprotected?: boolean
    noTenant?: boolean
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    const globalStore = useGlobalStore()
    const employeeController = useEmployeeController()
    globalStore.lastVisitedPage = to.fullPath?.toString() || "/"
    if (to.path === "/") {
      return next({ name: "quotes" })
    }

    if (to.meta.unprotected) {
      return next()
    }

    if (!globalStore.hydrated) {
      await globalStore.hydrate()
    }

    if (employeeController.shouldRedirectToProfile() && to.name !== "profile") {
      return next({ name: "profile" })
    }

    if (to.query.tenant) {
      return next()
    }
    return next({
      ...to,
      query: {
        ...to.query,
        tenant: globalStore.tenant?.id,
      },
    })
  },
)

router.afterEach(() => {
  const hash = window.location.hash
  if (hash.includes("access_token")) window.location.hash = ""
})

export default router
