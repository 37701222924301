<template>
  <div v-if="globalStore.hydrated">
    <div class="flex max-h-dvh min-h-dvh flex-col bg-2-static">
      <div class="bg-2-static pt-safe"></div>
      <div>
        <LayoutNavbar v-if="!mobile || showLayoutNavbar" />
      </div>
      <div class="flex grow overflow-y-auto">
        <LayoutSidebar v-if="!mobile" class="pr-16" />
        <div
          class="hide-scrollbar container mx-auto flex w-full max-w-6xl flex-col overflow-y-auto"
        >
          <RouterView />
          <BottomNav v-if="mobile && showBottomNav" />
          <ReleaseNotes />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const globalStore = useGlobalStore()
const showBottomNav = ref(false)
const showLayoutNavbar = ref(false)
const mobile = inject("isPhone") as Ref<boolean>
provide("showBottomNav", showBottomNav)
provide("showLayoutNavbar", showLayoutNavbar)
</script>
