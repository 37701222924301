<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    class="w-6 text-color-link-static"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.29289 9.70711L6.70711 8.29289L12 13.5858L17.2929 8.29289L18.7071 9.70711L12 16.4142L5.29289 9.70711Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts"></script>
