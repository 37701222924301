<template>
  <div class="absolute">
    <dialog
      :id="localId"
      class="z-10 p-6 model rounded-2xl mobile:w-full desktop:max-w-lg bg-1-static"
    >
      <div class="flex items-center mb-4">
        <IconButton v-if="type === 'info'" @click="$emit('close')" />
        <p v-if="props.title !== ''" class="text-label-bold-1 text-color-label-1">
          {{ props.title }}
        </p>
      </div>
      <p class="pb-4 text-label-2 text-color-label-3">
        {{ props.subTitle }}
      </p>
      <slot></slot>
      <div class="flex justify-around">
        <Button
          v-if="props.type === 'confirm'"
          :loading="loading"
          type="secondary"
          class="w-2/5"
          size="small"
          @click="$emit('y')"
          >{{ $t("yes") }}</Button
        >
        <Button
          v-if="props.type === 'confirm'"
          class="w-2/5"
          size="small"
          @click="$emit('n')"
          >{{ $t("no") }}</Button
        >
      </div>
    </dialog>
  </div>
</template>

<script setup lang="ts">
type Props = {
  loading?: boolean
  show: boolean
  title?: string
  subTitle?: string
  type?: "confirm" | "info"
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  show: true,
  title: "",
  subTitle: "",
  type: "confirm",
})
const localId = "modal-" + Math.random().toString(36).substring(7)
const dialog = ref<HTMLDialogElement | null>(null)
onMounted(() => {
  dialog.value = document.querySelector(`#${localId}`)
})
watch(
  () => props.show,
  show => {
    if (show) {
      dialog.value?.showModal()
      setTimeout(() => {
        dialog.value?.focus()
      }, 1)
    } else {
      dialog.value?.close()
    }
  },
  { immediate: true },
)
</script>
<style lang="postcss">
.model::backdrop {
  background: black;
  opacity: 0.5;
}
</style>
