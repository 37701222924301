import api from "@/api"
import { t } from "@/localization/i18n"
import { useGlobalStore } from "@/stores/globalStore"
import { useTenantStore } from "@/stores/tenantSettingsStore"
import { DirectusContext, Toaster } from "@/types"
import { fileUrl } from "@/utils/fileUrl"
import { defineStore } from "pinia"
import { inject } from "vue"
import { Capacitor } from "@capacitor/core"
import { Camera, CameraResultType, CameraSource, Photo } from "@capacitor/camera"

const platform = Capacitor.getPlatform()

interface TenantSettingsState {
  isLoading: boolean
  file: File | null
  fileName: string
  fileLocalUrl: string
  uploadedFileId: string
  globalStore: ReturnType<typeof useGlobalStore>
  tenantStore: ReturnType<typeof useTenantStore>
}

export const useFileStore = defineStore("file", {
  state: (): TenantSettingsState => ({
    isLoading: false,
    file: null,
    fileName: "",
    fileLocalUrl: "",
    uploadedFileId: "",
    globalStore: useGlobalStore(),
    tenantStore: useTenantStore(),
  }),
  actions: {
    addFileFromInput(event: Event) {
      const toast = inject("toast") as Toaster
      const target = event.target as HTMLInputElement
      if (target.files === null || target.files?.length === 0) return
      const file = target.files[0]
      const acceptedTypes = ["image/jpeg", "image/png", "image/webp"]
      if (!acceptedTypes.includes(file.type)) {
        toast.error(t("file_type_not_accepted", { file_types: acceptedTypes.join(", ") }))
        target.value = ""
        return
      }
      if (file.size > 500 * 1024) {
        toast?.error(t("file_size_error") + " 500KB")
        target.value = ""
        return
      }

      this.fileName = file.name
      this.file = file
    },
    async retrieveImage(): Promise<boolean> {
      try {
        let image: Photo | null = null

        if (platform === "web") {
          image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            source: CameraSource.Photos,
          })
        } else {
          image = await Camera.getPhoto({
            quality: 95,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            source: CameraSource.Prompt,
          })
        }
        if (image.webPath) {
          await this.convertUrlToFile(image.webPath)
          this.fileLocalUrl = image.webPath || ""
          return true
        } else {
          return false
        }
      } catch (error) {
        console.error("Error accessing camera:", error)
        return false
      }
    },
    async convertUrlToFile(imagePath: string) {
      try {
        const response = await fetch(imagePath)
        const blob = await response.blob()
        const file = new File([blob], `image.${blob.type}`, { type: blob.type })
        const acceptedTypes = ["image/jpeg", "image/png", "image/webp"]
        if (!acceptedTypes.includes(file.type)) {
          console.error("File type not accepted")
          return
        }
        this.file = file
      } catch (error) {
        console.error("Error handling image:", error)
      }
    },
    async uploadFile(context: DirectusContext): Promise<string> {
      if (!this.file) {
        console.error("No file to upload")
        return ""
      }
      this.isLoading = true
      const responseId = await api.uploadFile(this.file, this.globalStore.tenant.id)
      if (!responseId) {
        console.error("No response id when saving")
        return ""
      }
      this.file = null
      this.uploadedFileId = responseId
      const { collection, id, property } = context
      if (!collection || !id || !property) {
        console.error("No collection, id or property when saving")
        return ""
      }
      if (collection === "directus_users" && property) {
        await api.users.updateMe({
          [property]: responseId,
        })
      } else {
        await api.updateGeneric({ collection, id, data: { [property]: responseId } })
      }
      this.isLoading = false
      return responseId
    },
  },
  getters: {
    async getUploadedFileUrl(): Promise<string> {
      return await fileUrl(this.uploadedFileId, this.globalStore.token)
    },
  },
})
