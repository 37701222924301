import { Ref, computed } from "vue"

interface TextAreaHeightProps {
  rows: number
  mobile: Ref<boolean>
  isExpanded: Ref<boolean>
  isFocused: Ref<boolean>
  shouldAutoCollapse: Ref<boolean>
  input: Ref<HTMLTextAreaElement | null>
}

export function useTextAreaHeight(props: TextAreaHeightProps) {
  const contentHeight = computed(() => {
    if (!props.input.value) return `${props.rows * 24}px`
    return `${props.input.value.scrollHeight + 32}px`
  })

  const currentHeight = computed(() => {
    if (
      !props.mobile.value ||
      !props.shouldAutoCollapse.value ||
      props.isExpanded.value ||
      props.isFocused.value
    ) {
      return contentHeight.value
    }
    return `${props.rows * 24}px`
  })

  function grow() {
    if (!props.input.value) return
    props.input.value.style.height = "auto"
    props.input.value.style.height = `${props.input.value.scrollHeight + 32}px`
  }

  return {
    currentHeight,
    grow,
  }
}
